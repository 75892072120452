import React from 'react';

const PollPostIcon = () => {
  return (
    <svg
      id="Poll_Card"
      data-name="Poll Card"
      xmlns="http://www.w3.org/2000/svg"
      width="21.909"
      height="27"
      viewBox="0 0 21.909 27"
    >
      <g id="Layer_2" data-name="Layer 2" transform="translate(2.689 5.311)">
        <g id="Social_Icon" data-name="Social Icon" transform="translate(0)">
          <g id="PollHorizontal">
            <path
              id="Path_17660"
              data-name="Path 17660"
              d="M38.135,49.013a2.911,2.911,0,0,1-.042-5.823h8.5A2.922,2.922,0,0,1,49.5,46.064a2.913,2.913,0,0,1-2.875,2.948H38.133Zm0-4.55a1.641,1.641,0,0,0-1.176,2.783,1.631,1.631,0,0,0,1.154.5H46.59a1.641,1.641,0,0,0,.022-3.281Z"
              transform="translate(-35.218 -43.19)"
              fill="#b4b4b4"
            />
            <path
              id="Path_17661"
              data-name="Path 17661"
              d="M37.183,87.336c-1.063,0-1.94-1.225-1.954-2.73a3.429,3.429,0,0,1,.554-1.961,1.729,1.729,0,0,1,1.372-.834H47.189c1.061,0,1.935,1.223,1.949,2.726s-.843,2.765-1.915,2.8a.414.414,0,0,1-.055,0Zm.019-4.318c-.607,0-1.1.7-1.1,1.556s.493,1.556,1.1,1.556h9.985c.6,0,1.09-.688,1.1-1.533s-.477-1.565-1.083-1.579Z"
              transform="translate(-35.228 -75.779)"
              fill="#b4b4b4"
            />
            <path
              id="Path_17768"
              data-name="Path 17768"
              d="M36.568,85.6c-.729,0-1.33-.839-1.339-1.871a2.35,2.35,0,0,1,.38-1.344,1.185,1.185,0,0,1,.941-.572h6.877c.727,0,1.326.838,1.336,1.868s-.578,1.9-1.312,1.916l-.038,0Zm.013-2.959c-.416,0-.754.478-.754,1.066s.338,1.066.754,1.066h6.843c.409,0,.747-.472.753-1.051s-.327-1.072-.743-1.082Z"
              transform="translate(-35.228 -68.92)"
              fill="#b4b4b4"
            />
          </g>
        </g>
      </g>
      <path
        id="Path_17663"
        data-name="Path 17663"
        d="M23.454,35.5a1.51,1.51,0,0,1-1.105-.488,1.7,1.7,0,0,1-.459-1.174V10.163a1.7,1.7,0,0,1,.459-1.174A1.51,1.51,0,0,1,23.454,8.5H42.236a1.5,1.5,0,0,1,1.105.488,1.7,1.7,0,0,1,.459,1.174V33.837A1.628,1.628,0,0,1,42.282,35.5H23.454Zm0-25.843a.457.457,0,0,0-.335.149.521.521,0,0,0-.14.357V33.837a.515.515,0,0,0,.14.357.457.457,0,0,0,.335.149H42.236a.491.491,0,0,0,.474-.506V10.163a.515.515,0,0,0-.14-.357.457.457,0,0,0-.335-.149Z"
        transform="translate(-21.89 -8.5)"
        fill="#b4b4b4"
        fill-rule="evenodd"
      />
    </svg>
  );
};

export default PollPostIcon;
