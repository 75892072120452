import { FileOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Row, Spin, Typography, Upload, message } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import ReactQuillComponent from '../common/ReactQuillComponent';
import SelectUploadedImages from '../SelectUploadedImages';
import './reportPostForm.scss';
import CommonButton from '../../../common/CommonButton';

const ReportPostForm = ({
  setAllposts = () => {},
  postRel = 'user_post',
  setShowForm = () => {},
  setActiveTab = () => {},
  isCompany,
  isPostFromMyAccount = false,
}) => {
  // hooks
  const dispatch = useDispatch();
  const { Text } = Typography;

  // states
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);
  const userv2 = useSelector((state) => state.user?.userV2);
  const userId = useSelector((state) => state.user?.user?.id);

  // form derails
  const [formDetails] = Form.useForm();

  // states
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [filesUrls, setFilesUrls] = useState([]);
  const [filesNames, setFilesNames] = useState([]);

  // handle image upload
  const handleUpload = async (file, type = 'image') => {
    const formData = new FormData();
    formData.append('files', file);
    setImageloader(type);
    dispatch(uploadPostImage(formData))?.then((res) => {
      setImageloader(false);
      message.success(`${file?.name} uploaded successfuly...`);
      if (res?.payload?.data?.response?.data) {
        if (type == 'image') {
          setImgNames((prev) => [...(prev || []), file?.name]);
          setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        } else if ((type = 'file')) {
          setFilesNames((prev) => [...(prev || []), file?.name]);
          setFilesUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
        }
      }
    });
  };

  // handle submit
  const handleFormSubmit = (e) => {
    const createPostPayload = {
      title: e?.post_title || '',
      type: 'report_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      postDetails: {
        body: e?.post_caption,
        synopsys: e?.post_synopsys,
        link: e?.link,
        pdfUrl: filesUrls?.[0],
      },
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'report_card',
            title: e?.post_title || '',
            body: e?.post_caption || '',
            ownerId: userId,
            ownerType: 'user_post',
            postDetails: {
              body: e?.post_caption || '',
              synopsys: e?.post_synopsys || '',
              tags: e?.post_synopsys || [],
              link: e?.post_synopsys || '',
              pdfUrl: e?.filesUrls?.[0],
            },
            likes: 0,
            comments: 0,
            imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
            isOwner: true,
            ownerDetails: {
              _id: userId,
              name: userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));
        }
        message.success('Post created successfuly...');
        setShowForm(false);
      } else {
        message.error('Some error occured...');
      }
    });
  };

  return (
    <div className="reportPostCardContainer">
      {!isPostFromMyAccount && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark">Report Card</Text>
          </div>

          <div>
            <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
              Reset
            </CommonButton>
            <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
              Create Post
            </CommonButton>
          </div>
        </div>
      )}
      <Form
        className={`d-flex pt-20 ${isPostFromMyAccount && 'd-column'}`}
        layout="vertical"
        form={formDetails}
        onFinish={handleFormSubmit}
      >
        <div className={`imageContainer ${isPostFromMyAccount && 'w-100'}`}>
          <Form.Item name="imageURL" className="dropdown-form-item">
            <div className="d-flex d-column g-20 mb-20">
              <Upload
                listType="picture-card"
                className="uploadImage"
                multiple
                accept=".png,.jpeg,.jpg"
                customRequest={({ file }) => handleUpload(file, 'image')}
                showUploadList={false}
              >
                {imgLoader == 'image' ? (
                  <Spin indicator={<LoadingOutlined />} />
                ) : (
                  <>
                    {' '}
                    <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Image</Text>
                  </>
                )}
              </Upload>
            </div>
            {imgNames?.length > 0 && (
              <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
                {imgNames?.map((elem, index) => (
                  <span className="fontBlue" key={index}>
                    <FileOutlined /> {elem}
                  </span>
                ))}
              </div>
            )}
          </Form.Item>
          <Form.Item required label="" className="dropdown-form-item">
            <Form.Item name="imageURL">
              <SelectUploadedImages imageUrl={imgUrls} setImgUrl={setImgUrls} setImageNames={setImgNames} />
            </Form.Item>
          </Form.Item>
          <Form.Item label="Upload PDF" name="pdfURL" className="dropdown-form-item p-0">
            <div className="d-flex d-column g-20 mb-20">
              <Upload
                listType="picture-card"
                className="uploadPDF"
                multiple
                accept=".pdf"
                customRequest={({ file }) => handleUpload(file, 'file')}
                showUploadList={false}
              >
                {imgLoader == 'file' ? (
                  <Spin indicator={<LoadingOutlined />} />
                ) : (
                  <>
                    {' '}
                    <UploadOutlined style={{ fontSize: 22 }} /> <Text>Select File</Text>
                  </>
                )}
              </Upload>
              <div className="d-flex d-column g-5" style={{ maxHeight: '200px', overflow: 'scroll' }}>
                {filesNames?.map((elem, index) => (
                  <span className="fontBlue" key={index}>
                    <FileOutlined /> {elem}
                  </span>
                ))}
              </div>
            </div>
          </Form.Item>
        </div>
        <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
          <Form.Item required label="Title" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_title"
                className="buyerrow-form__space--text"
                rules={[
                  {
                    required: true,
                    message: 'Add Post Title!',
                  },
                ]}
              >
                <Input placeholder="Add Post Title" style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Flex>
          </Form.Item>
          <Form.Item required label="Description" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_caption"
                className="buyerrow-form__space--text"
                rules={[
                  {
                    required: true,
                    message: 'Add Description!',
                  },
                ]}
              >
                <ReactQuillComponent />
              </Form.Item>
            </Flex>
          </Form.Item>

          <Form.Item label="Link" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="link"
                rules={[
                  {
                    message: 'Please enter valid url.',
                    pattern:
                      '^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$',
                  },
                ]}
              >
                <Input placeholder="http://" style={{ width: '100%' }} min={0} />
              </Form.Item>
            </Flex>
          </Form.Item>

          <Form.Item required label="Synopsys" className="dropdown-form-item">
            <Flex gap="middle" horizontal>
              <Form.Item
                name="post_synopsys"
                className="buyerrow-form__space--text"
                rules={[
                  {
                    required: true,
                    message: 'Add Synopsys!',
                  },
                ]}
              >
                <ReactQuillComponent />
              </Form.Item>
            </Flex>
          </Form.Item>

          {isPostFromMyAccount && (
            <Row className="d-flex jc-end ">
              <div className="d-flex jc-end g-20 ">
                <Button type="primary" ghost onClick={() => setShowForm(false)}>
                  Reset
                </Button>
                <Button htmlType="submit" loader={loader} type="primary">
                  Create Post
                </Button>
              </div>
            </Row>
          )}
        </div>
      </Form>
    </div>
  );
};

export default ReportPostForm;
