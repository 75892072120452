import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SelectedPropertyDetail from '../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetails';
import SelectedPropertyDetailsSkeleton from '../propertyDetailsPage/components/ProperyDetails/SelectedPropertyDetailsSkeleton';
import SimilarProperties from '../propertyDetailsPage/components/ProperyDetails/SimilarProperties';
import {
  addBreadcrumb,
  deleteResources,
  saveResources,
  showSearchBar,
  viewMoreResidentialProperty,
} from '../../features/searchSlice';
import SelectedUnits from './SelectedUnits';
import './ResidentialPropertyDetailsPage.scss';
import Search_assets from '../../../assets/images/search-assets.svg';
import Wishlist from '../searchPage/components/wishlist/Wishlist';
import { useParams } from 'react-router';

const ResidentialPropertyDetailsPage = () => {
  const dispatch = useDispatch();
  const { propertyId } = useParams();

  const loader = false;
  const { detailedPropertyData, wishlist, searchQuery, residentialPropertyData, occupantDetails, occupantsData } =
    useSelector((state) => state.search);
  const selectedPropertyData = detailedPropertyData;

  useEffect(() => {
    dispatch(showSearchBar(true));
    if (propertyId) {
      dispatch(viewMoreResidentialProperty(propertyId));
    }
  }, []);

  useEffect(() => {
    dispatch(addBreadcrumb({ name: `Connected Cards`, path: `/leadGen/connectedResidentialDetails/${propertyId}` }));
  }, [propertyId]);

  const onDeleteCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      deleteResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  const onSaveCard = (resourceId, resourceName, cardType, unlockedFields, resourceType) => {
    dispatch(
      saveResources({
        body: {
          payload: [
            {
              name: resourceName,
              resourceId: resourceId,
              resourceType,
              resourceSubType: cardType,
              unlockedFields,
            },
          ],
        },
      }),
    );
  };

  return (
    <>
      {loader ? (
        <div className="property_container">
          <div className="font14 font900 fontLight-3 mb-10">Property Selected</div>
          <SelectedPropertyDetailsSkeleton />
          <SimilarProperties
            loader={loader}
            propertyData={residentialPropertyData.filter((item) => item?._id !== selectedPropertyData?._id)}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
          <div className="property_container">
            <div className="font14 font900 fontLight-3 mb-10">Property Selected</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectedPropertyDetail
                propertyItem={
                  Array.isArray(residentialPropertyData) ? residentialPropertyData?.[0] : residentialPropertyData
                }
                onSaveCard={onSaveCard}
                onDeleteCard={onDeleteCard}
                residentialData={true}
              />
              <div className="similarproperty-div">
                <SimilarProperties
                  searchQuery={searchQuery}
                  propertyData={residentialPropertyData.filter((item) => {
                    if (Array.isArray(residentialPropertyData)) {
                      return item?._id !== residentialPropertyData?.[0]?._id;
                    }
                    return item?._id !== residentialPropertyData?._id;
                  })}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '75%' }}>
            <div className="font14 font900 fontLight-3 mb-10">Unit in Selected Property</div>
            <div style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
              <SelectedUnits
                searchQuery={searchQuery}
                selectedPropertyData={residentialPropertyData}
                occupantDetails={occupantDetails}
                propertyData={occupantsData?.filter((item) => {
                  if (Array.isArray(residentialPropertyData)) {
                    return item?._id !== residentialPropertyData?.[0]?._id;
                  }
                  return item?._id !== residentialPropertyData?._id;
                })}
              />
            </div>
          </div>
          <div style={{ background: 'white', width: '16%', borderRadius: '8px', zIndex: 2 }}>
            <Wishlist wishlist={wishlist} />
          </div>
          <div style={{ width: '100%', display: 'contents' }}>
            <img
              src={Search_assets}
              alt="Search"
              style={{
                width: '73%',
                position: 'absolute',
                objectFit: 'cover',
                objectPosition: 'center',
                top: '73%',
                opacity: 0.8,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ResidentialPropertyDetailsPage;
