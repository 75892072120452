import { nanoid } from '@reduxjs/toolkit';
import { Card, Col, Divider, List, Rate, Row } from 'antd';
import { isEmpty, truncate } from 'lodash';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import bookmark from '../../../../../assets/images/bookmark.svg';
import bookmark_selected from '../../../../../assets/images/bookmark_selected.svg';
import aminities from '../../../../../assets/images/Icon feather-home.svg';
import locationSVG from '../../../../../assets/images/address-location.svg';
import animationData from '../../../../../assets/images/PropertyAndComapnyLottieImage.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';
import { populateAddressInfo } from '../../../../../utils/formatSearchData/Address';
import AmenitiesIconEnum from '../../../../../utils/referenceData/search/amenitiesIconEnum';
import { getSearchResults } from '../../../../features/searchSlice';
import KebabMenu from '../../../../components/kebabMenu/KebabMenu';
import Ready from '../../../../../assets/images/ready.svg';
import UnderConstruction from '../../../../../assets/images/UnderConstruction.svg';
import '../../../searchPage/searchPage.scss';
import './residentialProperty.scss';
import RaiseConcern from '../../../../../layout/universal/components/raiseConcern/RaiseConcern';
import { ShareModal } from '../../../../../utils/ShareModal/ShareModal';

const ResidentialProperty = ({ propertyData, onSaveCard, onDeleteCard }) => {
  const { searchQuery, pagination, hasMoreData } = useSelector((store) => store.search);
  const navigateTo = useNavigate();
  const [showShareModal, setShowShareModal] = useState(null);
  const dispatch = useDispatch();

  const [showRaiseConcern, setShowRaiseConcern] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});

  // Function to show RaiseConcern
  const handleRaiseConcernClick = (propertyItem) => {
    setSelectedProperty(propertyItem);
    setShowRaiseConcern(true);
  };

  // Function to close RaiseConcern
  const handleCloseRaiseConcern = () => {
    setShowRaiseConcern(false);
    setSelectedProperty({});
  };

  const loadMoreData = () => {
    dispatch(
      getSearchResults({
        body: {
          query: searchQuery?.body?.query,
          offset: pagination?.offset,
          limit: pagination?.limit,
        },
      }),
    );
  };

  const navigateToPropertyDetail = (e, id, page) => {
    e.stopPropagation();
    navigateTo(`/leadGen/propertyDetails/${id}?list=${page}`, { replace: true });
  };
  return isEmpty(propertyData) ? (
    <></>
  ) : (
    <div style={{ paddingBottom: '200px' }}>
      {!isEmpty(propertyData) && (
        <div style={{ display: 'flex', justifyContent: 'flex-start', height: '30px', gap: '30px' }}>
          <div style={{ color: '#9f9f9f', marginLeft: '126px' }}>
            Your search result for Residential property as{' '}
            <i style={{ color: '#48a4fc', fontWeight: '600' }}>{searchQuery?.body?.query}</i> and similar properties
          </div>
        </div>
      )}
      <div id="columnOneDiv" style={{ height: '100vh', overflow: 'auto', paddingBottom: '100px' }}>
        <InfiniteScroll
          dataLength={propertyData?.length}
          next={() => {
            loadMoreData();
          }}
          hasMore={hasMoreData}
          endMessage={<Divider plain>It is all, nothing more </Divider>}
          scrollableTarget="columnOneDiv"
        >
          <List
            dataSource={propertyData}
            renderItem={(propertyItem) => (
              <List.Item key={nanoid()} style={{ borderBlockEnd: 'none' }}>
                <div key={propertyItem?._id} className="propertyListContainer__div">
                  <Card
                    className="propertyListContainer__div__card cursor-pointer"
                    onClick={() => {
                      navigateTo(`/leadGen/connectedResidentialDetails/${propertyItem?._id}`);
                    }}
                  >
                    <Row className="inside_row">
                      <div style={{ position: 'absolute', zIndex: 3, marginLeft: '20px', marginTop: '-8px' }}>
                        {propertyItem?.buildingStatus === 'Ready' && <img src={Ready} alt="Ready" />}
                        {propertyItem?.buildingStatus === 'Under Construction' && (
                          <img src={UnderConstruction} alt="Under Construction" />
                        )}
                      </div>
                      <Col
                        span={7}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        className="propertyListContainer__div__card__row__col__row__col"
                      >
                        {propertyItem?.assetsInfo?.[0]?.featuredImage ? (
                          <img
                            src={propertyItem?.assetsInfo?.[0]?.featuredImage}
                            alt="Property Img"
                            className="propertyListContainer__div__card__row__col__row__col__img"
                          />
                        ) : (
                          <LottieAnimation height={70} width={100} animationData={animationData} loop={true} />
                        )}
                      </Col>
                      <Col span={16} className="d-flex d-column jc-between">
                        <div>
                          <div className="d-flex jc-between a-center mt-10 ">
                            <h5
                              style={{ color: '#0081FC' }}
                              className="text-underline propertyListContainer__div__card__row__col__row__col1__h5"
                              // onClick={() => {
                              //   navigateTo(`/leadgen/propertyDetails/${propertyItem?._id}`);
                              // }}
                            >
                              {propertyItem?.buildingName}
                            </h5>
                            <div className="d-flex">
                              <div>
                                {!propertyItem?.isSaved && (
                                  <img
                                    src={bookmark}
                                    alt="bookmark"
                                    style={{
                                      visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      height: '25px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onSaveCard(
                                        propertyItem?._id,
                                        propertyItem?.buildingName,
                                        'property',
                                        ['representativeInfo'],
                                        'commercial',
                                      );
                                    }}
                                  />
                                )}
                                {propertyItem?.isSaved && (
                                  <img
                                    src={bookmark_selected}
                                    alt="bookmark_selected"
                                    style={{
                                      visibility: !propertyItem?.isResourceLocked ? 'hidden' : 'visible',
                                      cursor: 'pointer',
                                      height: '25px',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      onDeleteCard(
                                        propertyItem?._id,
                                        propertyItem?.buildingName,
                                        'property',
                                        ['representativeInfo'],
                                        'commercial',
                                      );
                                    }}
                                  />
                                )}
                              </div>
                              <KebabMenu
                                onShare={() => {
                                  setShowShareModal(
                                    `${window.location.host}/leadgen/connectedResidentialDetails/${propertyItem?._id}?shareable=true`,
                                  );
                                }}
                                onRaiseConcern={() => handleRaiseConcernClick(propertyItem)}
                              />
                            </div>
                          </div>
                          <div
                            className="propertyListContainer__div__card__row__col__row__col1__para"
                            style={{ color: '#313131' }}
                          >
                            {`${propertyItem?.addressInfo?.[0]?.locality}, ${propertyItem?.addressInfo?.[0]?.city}`}
                          </div>
                          <Rate
                            allowHalf
                            disabled
                            defaultValue={
                              !isNaN(Number(propertyItem?.averageRating)) ? Number(propertyItem?.averageRating) : 0
                            }
                          />
                          <div className="d-flex  d-column g-5 mt-10">
                            <div className="d-flex g-5">
                              <img
                                src={locationSVG}
                                style={{ marginTop: 3 }}
                                alt="location"
                                className="propertyListContainer__div__card__row1__col1__row__col__img"
                              />
                              <span className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                {truncate(populateAddressInfo(propertyItem?.addressInfo?.[0]), { length: 90 })}
                              </span>
                            </div>
                            <div className="d-flex g-5 a-center">
                              <img
                                src={aminities}
                                alt="aminities"
                                className="propertyListContainer__div__card__row1__col1__row__col__img"
                              />
                              <p className="propertyListContainer__div__card__row1__col1__row__col1__para">
                                Amenities: Grade of building: A
                              </p>
                            </div>
                            <div style={{ display: 'inline-flex', justifyContent: 'space-around' }}>
                              {propertyItem?.amenities && (
                                <div
                                  className="propertyListContainer__div__card__row1__col1__div"
                                  style={{ maxHeight: '200px', display: 'flex' }}
                                >
                                  {propertyItem?.amenities &&
                                    propertyItem?.amenities?.length > 0 &&
                                    propertyItem?.amenities?.slice(0, 3)?.map((item) => {
                                      return (
                                        <Col
                                          span={6}
                                          className="propertyListContainer__div__card__row1__col1__div__col"
                                        >
                                          {isEmpty(AmenitiesIconEnum[item]) && (
                                            <img
                                              title={'DEFAULT'}
                                              src={AmenitiesIconEnum['DEFAULT']}
                                              className="propertyListContainer__div__card__row1__col1__div__col__img"
                                            />
                                          )}
                                          {!isEmpty(AmenitiesIconEnum[item]) && (
                                            <img
                                              title={item}
                                              src={AmenitiesIconEnum[item]}
                                              className="propertyListContainer__div__card__row1__col1__div__col__img"
                                            />
                                          )}
                                        </Col>
                                      );
                                    })}
                                </div>
                              )}
                              {propertyItem?.amenities?.length > 3 ? (
                                <p
                                  className="propertyListContainer__div__card__row1__col1__div__col__para"
                                  style={{ verticalAlign: 'top', marginTop: '5px', fontWeight: 'bolder' }}
                                >
                                  {`${propertyItem?.amenities?.length - 3} More...`}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="bottomRow d-flex jc-between">
                          <span
                            onClick={(e) => {
                              if (propertyItem?.vacantUnitCount)
                                navigateToPropertyDetail(e, propertyItem?._id, 'vacantUnits');
                              else e.stopPropagation();
                            }}
                            className={`residential-hover propertyListContainer__div__card__row1__col1__row__col1__para1  ${
                              propertyItem?.vacantUnitCount ? 'cursor-pointer' : 'cursor-default'
                            }`}
                          >
                            {`${propertyItem?.vacantUnitCount ?? 0} Unit Available`}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </List.Item>
            )}
          />
        </InfiniteScroll>
      </div>
      {showRaiseConcern && (
        <RaiseConcern
          resourceDetails={{
            resourceType: 'residential',
            resourceSubType: 'Property',
            resourceName: selectedProperty?.buildingName,
            resourceId: selectedProperty?._id,
          }}
          onClose={handleCloseRaiseConcern}
        />
      )}
      {showShareModal && (
        <ShareModal shareUrl={showShareModal} showShareModal={showShareModal} setShowShareModal={setShowShareModal} />
      )}
    </div>
  );
};

export default ResidentialProperty;
