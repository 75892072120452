export const parseJson = (payload) => {
  try {
    return JSON.parse(payload);
  } catch {
    return false;
  }
};

export const isMobileNumber = (input) => {
  // Regular expression to match mobile numbers (assuming international format)
  const mobileNumberRegex = /^[0-9]{10,}$/;
  return mobileNumberRegex.test(input);
};

export const isEmail = (input) => {
  // Regular expression to match email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(input);
};

export const formatUTCTimeWithAmPm = (date) => {
  // Get UTC hours, minutes, and seconds
  let utcHours = date.getUTCHours();
  let utcMinutes = date.getUTCMinutes();

  // Determine AM or PM suffix
  let ampm = utcHours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour format to 12-hour format
  utcHours = utcHours % 12;
  utcHours = utcHours ? utcHours : 12; // If hours is 0, set it to 12

  // Format minutes to always have two digits
  utcMinutes = utcMinutes < 10 ? '0' + utcMinutes : utcMinutes;

  // Return formatted time string
  return `${utcHours}:${utcMinutes} ${ampm}`;
};
