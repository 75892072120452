import React, { useCallback, useState } from 'react';
import './leadCard.scss';
const LeadCard = () => {
  return (
    <div className="font12 fontWhite d-flex leadCardContainer d-column jc-between g-10">
      <div className="d-flex g-20 jc-between">
        <div className="font16">Looking for</div>
        <div>Unlock for 50 Points</div>
      </div>
      <div className="d-flex g-20 ">
        <div className="tag">Purchase</div>
        <div className="tag">Commercial</div>
      </div>
      <div>
        <div>Location Preference</div>
        <div>Baner,Pune ,Maharastra</div>
      </div>
      <div>
        <div>Details</div>
        <div>Looking to buy commerical for IT office setup and properties.</div>
      </div>
      <div className="d-flex g-20 jc-between">
        <div>
          <div>Budget Range</div>
          <div>Looking to buy commerical for IT office setup and properties.</div>
        </div>
        <div className="width-max-content">
          <div>Open to brokers</div>
          <div>Yes</div>
        </div>
      </div>
      <div className="bottomFooter">
        <span>Name: Lorem</span>
        <span>Name: Lorem</span>
        <span>Name: Lorem</span>
      </div>
    </div>
  );
};

const LeadCards = () => {
  const imageUrls = [1, 2, 3, 4];
  const [currentIndex, setCurrentIndex] = useState(0);
  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
  }, [imageUrls.length]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
  }, [imageUrls.length]);

  const goToIndex = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  return (
    <div className={`carousel w-100`}>
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)`, height: 'inherit' }}>
        {imageUrls.map((url, index) => (
          <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
            <LeadCard />
          </div>
        ))}
      </div>
      {imageUrls.length > 1 && (
        <div className="carousel-dots">
          {imageUrls.map((url, index) => (
            <button
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => goToIndex(index)}
            ></button>
          ))}
        </div>
      )}
      {imageUrls.length > 1 && currentIndex > 0 && (
        <button className="carousel-control prev" onClick={goToPrevious}>
          &#10094;
        </button>
      )}
      {imageUrls.length > 1 && currentIndex < imageUrls.length - 1 && (
        <button className="carousel-control next" onClick={goToNext}>
          &#10095;
        </button>
      )}
    </div>
  );
};
export default LeadCards;
