import { DeleteOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import React from 'react';

import Text from 'antd/es/typography/Text';
import { truncate } from 'lodash';
import CommentComponent from '../../../socialApp/posts/postFooter/CommentComponent';
import './postTypes.scss';

const ReportCardPost = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  return (
    <div className="profile-layout" gutter={16}>
      {/* <div className="report-profile-image position-relative"> */}
      {/* <img
          src={
            data?.imageUrls?.length > 0
              ? data?.imageUrls?.[0]?.url
              : 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai'
          }
          alt="Profile"
          className="profile-image"
        /> */}{' '}
      <div
        style={{
          background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
              data.imageUrls?.[0]?.url || defaultImage
            })
`,
        }}
        className="report-profile-image"
      >
        <span className="postImageTitle">{data?.title}</span>
        {data?.postDetails?.pdfUrl && (
          <a target="_blank" href={data?.postDetails?.pdfUrl} download="report-card" className="postImageFooter">
            <DownloadOutlined style={{ fontSize: 16 }} />
            Download full Report
          </a>
        )}
      </div>
      {/* </div> */}
      <div className="content-col" style={{ width: '100%' }}>
        <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
          <span className="d-flex g-15 a-center ">
            <Avatar src={user?.personalDetails?.imageUrl} icon={<UserOutlined />} />
            {isCompany ? (
              <div className="d-flex d-column">
                {companyData?.name && (
                  <span className="font14 fontExtraLight ">{user?.professionalDetails?.companyName}</span>
                )}
                {companyData?.address && (
                  <span className="font14 fontExtraLight ">
                    {companyData?.address?.city},{companyData?.address?.state}
                  </span>
                )}
              </div>
            ) : (
              <div className="d-flex d-column">
                <Text style={{ fontWeight: '600', color: '#535353' }}>{user?.name}</Text>
                <div className="d-flex">
                  {user?.professionalDetails?.designation && (
                    <Text
                      style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                    >{`${user?.professionalDetails?.designation} @`}</Text>
                  )}
                  {user?.professionalDetails?.companyName && (
                    <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                      {user?.professionalDetails?.companyName}
                    </Text>
                  )}
                </div>
              </div>
            )}
          </span>
          <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }}>
            <DeleteOutlined onClick={handleDeletPost} />
          </div>
        </div>
        <Text id="post-title" style={{ fontWeight: '600', color: '#535353' }}>
          {truncate(data?.title, { length: 40 })}
        </Text>
        <div
          id="post-synopsys"
          // className="font14 postBody fontExtraLight"
          style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}
          dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.synopsys, { length: 142 })) }}
        ></div>
        <div
          id="post-body"
          // className="font14 postBody fontExtraLight"
          style={{ fontWeight: '600', color: '#535353', marginTop: '10px' }}
          dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.body, { length: 142 })) }}
        ></div>
        <Text className="cursor-pointer" style={{ fontWeight: '600', color: '#0081FC' }} onClick={handleSeeMore}>
          See more
        </Text>
      </div>
      {postData && (
        <Modal
          styles={{
            mask: {
              backdropFilter: 'blur(6px)',
            },
          }}
          centered
          title={null}
          open={isVisiable}
          onCancel={handleCancel}
          footer={null}
          width={820}
        >
          <CommentComponent
            isUserPost={true}
            setAllPosts={setAllPosts}
            setPostData={setPostData}
            postDetails={postData}
            postId={postData?._id}
            userId={user?._id}
          />
        </Modal>
      )}
    </div>
  );
};

export default ReportCardPost;
