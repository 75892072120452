import { Card, Col, Space, Tag, Modal, Button } from 'antd';
import ProspectImage from '../../components/prospectImage/ProspectImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import PhotoGallery from '../../components/photoGallery/PhotoGallery';
import Reviews from '../../components/reviews/Reviews';
import DataGrid from '../../components/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailedPropertyInfo,
  getOccupantsFromProperty,
  setNavigateFrom,
  getVacantUnitsFromProperty,
  unlockVacantUnitFields,
  addBreadcrumb,
  truncateBreadcrumbs,
} from '../../features/searchSlice';
import { isEmpty } from 'lodash';
import AmenitiesIconEnum from '../../../utils/referenceData/search/amenitiesIconEnum';
import { FormatVacantUnitsData } from '../../../utils/formatSearchData/VacantUnit';
import { UserOutlined } from '@ant-design/icons';
import { getPropertyReviews } from '../../features/contactBookSlice';
import properties from '../../../assets/images/WALL.svg';
import search_organization from '../../../assets/images/search_organization.svg';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';

import Address from '../../components/address/Address';
import { populateAddressInfo } from '../../../utils/formatSearchData/PropertyAddress';
import { transactProspects } from '../../features/savedSlice';
import { setInsufficientPoints, setTransactionModal } from '../../features/savedSlice';
import './ResidentialPropertyDetailsCard.scss';

const ResidentialPropertyDetailsCard = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detailedResidentialPropertyData, vacantUnitsData, breadcrumbs } = useSelector((store) => store.search);
  const { propertyReviews } = useSelector((store) => store.contactBook);

  const [propertyDetails, setPropertyDetails] = useState({});
  const [reviwesDetails, setReviewsDetails] = useState([]);
  const [vacantUnitDetails, setVacantUnitDetails] = useState([]);

  const { selectedNav } = useSelector((store) => store.leadGenSideNav);
  const { userPoints } = useSelector((store) => store.userOnboarding);
  const { transactionLoading } = useSelector((store) => store.saved);
  const { crmData } = useSelector((store) => store.userDashboard);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const vacantUnitListColumns = [
    {
      title: '',
      dataIndex: 'vacantProfileImage',
      key: 'vacantProfileImage',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.vacantProfileImage ? (
              <img
                src={record?.vacantProfileImage}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Chargable Area',
      dataIndex: 'chargableArea',
      key: 'chargableArea',
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: (text, record) => {
        return (
          <span className="unit-property-doc-type">
            {record?.transactionType ? record?.transactionType : 'Awaited'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'isResourceLocked',
      key: 'isResourceLocked',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}</div>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text._id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getDetailedPropertyInfo(id));
    dispatch(getOccupantsFromProperty(id));
    dispatch(getPropertyReviews(id));
    dispatch(getVacantUnitsFromProperty(id));
    if (detailedResidentialPropertyData?.buildingName) {
      dispatch(
        addBreadcrumb({
          name: `${detailedResidentialPropertyData?.buildingName}`,
          path: `/leadGen/propertyDetails/${id}`,
        }),
      );
    }
  }, [id]);

  useEffect(() => {
    if (isEmpty(detailedResidentialPropertyData)) return;
    setPropertyDetails(detailedResidentialPropertyData);
  }, [detailedResidentialPropertyData]);

  useEffect(() => {
    if (isEmpty(propertyReviews)) return;
    setReviewsDetails(propertyReviews);
  }, [propertyReviews]);

  useEffect(() => {
    if (isEmpty(vacantUnitsData)) return;
    const data = FormatVacantUnitsData(vacantUnitsData);
    setVacantUnitDetails(data);
  }, []);

  const onRowClick = (id) => {
    const vacantUnitData = vacantUnitDetails?.filter((item) => item?.id === id);
    if (vacantUnitData?.[0]?.isResourceLocked == false) {
      navigate(`/leadGen/unitDetails/${id}`);
      return;
    }
    const transactPayload = {
      body: {
        payload: [
          {
            resourceType: 'commercial',
            resourceSubType: 'vacantUnit',
            resourceId: id,
            unlockedFields: ['address', 'poc'],
          },
        ],
      },
    };
    dispatch(unlockVacantUnitFields(transactPayload))
      .then((res) => {
        if (res?.error?.message === 'Rejected') {
          dispatch(setTransactionModal(true));
          dispatch(setInsufficientPoints(100));
          return;
        }
        navigate(`/leadGen/unitDetails/${id}`);
      })
      .catch((err) => {
        console.log(err, 'err');
      });
    dispatch(setNavigateFrom('PropertyDetails'));
  };

  const handleTransacBody = (resourceSubType, selectedRecords) => {
    switch (resourceSubType) {
      case 'organization':
        return {
          Email: selectedRecords?.officemailid,
          FirstName: selectedRecords?.name,
          Mobile: selectedRecords?.headOfficeNumber,
          Website: selectedRecords?.websiteLink,
          Description: selectedRecords?.description,
          Micromarket: selectedRecords?.location?.mapLocation,
        };
      case 'connect':
        return {
          Email: selectedRecords?.personalDetails?.email,
          FirstName: selectedRecords?.personalDetails?.name,
          Mobile: selectedRecords?.personalDetails?.phone,
        };
      case 'property':
        return {
          FirstName: selectedRecords?.name,
          Micromarket: selectedRecords?.location?.mapLocation,
        };

      default:
        return;
    }
  };

  const handleUnlockProspects = (value) => {
    const selectedRecords = value;
    const body = {
      payload: selectedRecords
        ?.filter((data) => data?.resourceType === 'commercial')
        ?.map((el) => ({
          resourceType: el?.resourceType,
          resourceSubType: el?.resourceSubType,
          resourceId: el?.resourceId,
          unlockedFields: el.unlockedFields,
          crmPayload: {
            ...handleTransacBody(selectedRecords?.[0]?.resourceSubType, selectedRecords?.[0]),
          },
        })),
      crmDetails: {
        id: crmData?.id,
        accessToken: crmData?.accessToken,
      },
    };
    dispatch(transactProspects(body));
  };

  const showPopupModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const PopupModal = () => (
    <Modal
      centered
      open={isModalVisible}
      onOk={() => handleModalClose()}
      onCancel={() => handleModalClose()}
      cancelButtonProps={{ style: { display: 'none' } }}
      footer={
        <Button key="ok" type="primary" loading={transactionLoading} onClick={() => navigate('/user/store')}>
          Make Payment
        </Button>
      }
      className="savedModal"
    >
      <Space direction="vertical" align="center">
        <Card
          style={{
            width: 250,
            marginTop: '20px',
            background: 'transparent linear-gradient(123deg, #0081FC 0%, #66B3FD 100%) 0% 0% no-repeat padding-box',
            boxShadow: '0px 0px 20px #00000029',
            textAlign: 'center',
          }}
        >
          <Space direction="vertical">
            <Title style={{ color: '#FFFFFF' }} level={4}>
              Oops insufficient points!
            </Title>
            <Text style={{ color: '#FFFFFF' }}>You need additional {100 - userPoints} points to unlock</Text>
          </Space>
        </Card>
      </Space>
    </Modal>
  );

  const vacantUnitsList = [
    {
      _id: '6661e600c247e56b2fc71c81',
      transactionType: 'Sale',
      chargableArea: '1,299',
      registrationDate: '2023-01-31T00:00:00',
      rate: '550',
      address: {
        buildingName: 'Metropole',
        unitNo: '501',
        floorNo: '5',
      },
      assetsInfo: {
        featuredImage: 'https://propertyimagedata.s3.ap-south-1.amazonaws.com/Elevan%20West%20Profile.jpg',
      },
    },
    {
      _id: '6661e600c247e56b2fc71c81',
      transactionType: 'Rent',
      chargableArea: '1,199',
      registrationDate: '2023-01-31T00:00:00',
      rate: '500',
      address: {
        buildingName: 'Metropole',
        unitNo: '501',
        floorNo: '5',
      },
      assetsInfo: {
        featuredImage: 'https://propertyimagedata.s3.ap-south-1.amazonaws.com/Metropole2.jpg',
      },
    },
    {
      _id: '6661e600c247e56b2fc71c81',
      transactionType: 'Sale',
      chargableArea: '1,100',
      registrationDate: '2023-01-31T00:00:00',
      rate: '510',
      address: {
        buildingName: 'Metropole',
        unitNo: '501',
        floorNo: '5',
      },
      assetsInfo: {
        featuredImage: 'https://propertyimagedata.s3.ap-south-1.amazonaws.com/Elevan%20West%20Profile.jpg',
      },
    },
  ];

  return (
    <section className="personalDtlsSection">
      <Card className="personalDtlsSection-card">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Title level={4} strong className="card__title">
            <span
              onClick={() => {
                if (selectedNav !== 'ContactBook') {
                  dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                  navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                  return;
                }
                navigate('/leadgen/contactbook');
              }}
              className="card__title-pointer"
            >
              {'< '}
              <span style={{ fontSize: '16px', verticalAlign: 'middle', fontWeight: '600' }}>
                {propertyDetails?.buildingName}
              </span>
            </span>
          </Title>
          <div className="prospectImage">
            <ProspectImage imageUrl={propertyDetails?.featuredImage || properties} />
          </div>
          <Text style={{ fontWeight: '900' }}>Property Details</Text>
          <Text style={{ fontSize: '12px' }}>Developer Name : {propertyDetails?.developer}</Text>
          <Text style={{ fontSize: '12px' }}>E-Mail : {propertyDetails?.email}</Text>
          <Text style={{ fontSize: '12px' }}>Mobile No : {propertyDetails?.phone}</Text>
          <Text style={{ fontSize: '12px' }}>Society Maintanance Charges : {propertyDetails?.developer}</Text>
          <br />
          <Address addressInfo={populateAddressInfo(propertyDetails?.addressInfo)} />
          <br />
          <Text style={{ fontWeight: '900' }}>Brochure</Text>
        </Space>
      </Card>
      <Card className="personalDtlsSection-card">
        <Space direction="vertical">
          <Text style={{ fontWeight: '900' }}>About</Text>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Text style={{ fontSize: '12px' }}>{propertyDetails?.about}</Text>
          </div>
          <br />
          <Text style={{ fontWeight: '800' }}>Amenities</Text>
          <Text style={{ fontSize: '12px' }}>Grade of building: {propertyDetails?.gradeOfBuilding}</Text>
          <Text style={{ fontSize: '12px' }}>Total Floors: {propertyDetails.floors}</Text>
          {propertyDetails?.amenities?.general && (
            <div className="amenitiesDtlsSection__div">
              {propertyDetails?.amenities?.general?.map((item) => {
                return (
                  <Col key={item} span={6} className="amenitiesDtlsSection__div__col">
                    {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) ? (
                      <img src={AmenitiesIconEnum?.['DEFAULT']} className="amenitiesDtlsSection__div__col__img" />
                    ) : (
                      <img
                        src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                        className="amenitiesDtlsSection__div__col__img"
                      />
                    )}
                  </Col>
                );
              })}
            </div>
          )}
          <br />
          {propertyDetails?.areaFacilities && propertyDetails?.areaFacilities?.length > 0 && (
            <div className="propertyListContainer__div__card__row1__col1__div1">
              {propertyDetails?.areaFacilities?.map((item, i) => {
                if (item?.facility && item?.metadata?.location)
                  return (
                    <span key={i} className="propertyListContainer__div__card__row1__col1__div1__span">
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">{item?.facility}</p>
                      :&nbsp;{' '}
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">
                        {item?.metadata?.location}
                      </p>
                    </span>
                  );
                return '';
              })}
            </div>
          )}
          <Text style={{ fontWeight: '900' }}>Other Details</Text>
          <Text style={{ fontSize: '12px' }}>CAM/ sq.ft Charges : {propertyDetails?.averageCAM}</Text>
          <Text style={{ fontSize: '12px' }}>Floor Plate : {propertyDetails?.floorPlate}</Text>
          <Text style={{ fontSize: '12px' }}>Building Type : {propertyDetails?.buildingType}</Text>
          <Text style={{ fontSize: '12px' }}>
            Circle rate/ sq.ft : {propertyDetails?.moreBuildingInfo?.[0]?.averageCircleRate || 'Awaited'}
          </Text>
          <Text style={{ fontSize: '12px' }}>
            Average sale rate :{' '}
            {propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate
              ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate).toFixed(2)} Rs/sqft`
              : 'Awaited'}
          </Text>
          <Text style={{ fontSize: '12px' }}>
            Average rent rate :{' '}
            {propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate
              ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate).toFixed(2)} Rs/sqft`
              : 'Awaited'}
          </Text>
          <Text style={{ fontWeight: '900' }}>Location Advantages</Text>
          <div className="items-value" style={{ marginBottom: '16px', maxHeight: '120px', overflow: 'auto' }}>
            {propertyDetails?.locationAdvantages?.map((tag) => {
              return (
                <Tag key={tag} className="items-tag" color="#DEF0FF">
                  <Text className="tag-text">{tag}</Text>
                </Tag>
              );
            })}
          </div>
          <br />
          <PhotoGallery photos={propertyDetails?.propertyImages} />
          <br />
          <Reviews averageRating={propertyDetails?.averageRating} reviewsList={reviwesDetails} />
        </Space>
      </Card>
      <Card style={{ flex: '1.5' }} className="companiesList">
        <div className="tab-headings">
          <Text className="listHeading" style={{ color: '#313131' }}>
            List of Vacant Unit
          </Text>
        </div>
        <div className="data-grid-container">
          <DataGrid columns={vacantUnitListColumns} data={vacantUnitsList} onRowClick={(id) => onRowClick(id)} />
        </div>
      </Card>
      <PopupModal />
    </section>
  );
};

export default ResidentialPropertyDetailsCard;
