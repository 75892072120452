import { Divider } from 'antd';
import React from 'react';
import { createPostTypes, createPostTypes2 } from './posts.utils';

const AddPost = ({ setShowCreatePopup, showPostForm, setShowPostForm, imageUrl = '' }) => {
  const handleCardClick = (key) => {
    setShowPostForm(key);
  };

  // const FormComponent = formMaps?.[showPostForm];
  return (
    <div className="socialAppContainer__postContainer--addPost">
      <div className="font16 font700 p-5">Create your post</div>
      <Divider className="addPostdivider" />
      <div className="d-flex jc-around a-center pt-20 pb-10">
        {createPostTypes2?.map((card, index) => {
          const Icon = card?.icon;
          return (
            <div key={card?.key} className={`addPostIcons  cursor-pointer ${card?.disabled ? 'disabled' : ''}`}>
              <div className="d-flex d-column a-center" g-5 onClick={() => handleCardClick(card?.key)} key={index}>
                <div className="svgContainer"> {Icon && <Icon />}</div>
                <span className="cardLabel">{card?.label}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddPost;
