import { DeleteOutlined, EditOutlined, LeftOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import { formatDistanceToNow } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';
import InputEmoji from 'react-input-emoji';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteComment,
  getComments,
  socialAppFollowUser,
  socialAppUnFollowUser,
  updateActivity,
  updateComment,
} from '../../../../features/socialAppSlice';
import GenericPost from '../allPosts/genericPost/GenericPost';
import NewsCardPost from '../allPosts/newsCardPost/NewsCardPost';
import ReportCardPost from '../allPosts/reportCardPost/ReportCardPost';
import './CommentComponent.scss';
import AdCardPost from '../allPosts/adCardPost/AdCardPost';
import EventCardPost from '../allPosts/eventCardPost/EventCardPost';
import LeadCardPost from '../allPosts/leadCardPost/LeadCardPost';
import JobCardPost from '../allPosts/jobCardPost/JobCardPost';

const CommentComponent = ({
  isDashboardPost,
  isUserPost,
  setAllPosts = () => {},
  setPostData = () => {},
  userId,
  postId,
  postDetails,
  setPostDetails = () => {},
}) => {
  const dispatch = useDispatch();
  const comments = useSelector((state) => state.socialAppSlice.socialAppComments);
  const loading = useSelector((state) => state.socialApp?.socialApploader);
  const error = useSelector((state) => state.socialApp?.error);
  const [newComment, setNewComment] = useState('');
  const [editCommentText, setEditCommentText] = useState('');
  const [editingComment, setEditingComment] = useState(null);
  const [showEmoji, setShowEmoji] = useState(false);
  const emojiInputRef = useRef();
  useEffect(() => {
    if (userId && postId) {
      dispatch(getComments({ userId, postId }));
    }
  }, [userId, postId]);

  const handleAddComment = async () => {
    if (newComment.trim() !== '') {
      dispatch(updateActivity({ userId, postId, action: 'comments', text: newComment }))?.then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 + 1,
            }));
            setPostDetails((prev) => ({
              ...prev,
              comments: prev?.comments || 0 + 1,
            }));
          }
          setAllPosts((prev) => ({
            ...prev,
            posts: prev?.posts?.map((elem) => {
              if (elem?._id === postDetails?._id) {
                elem = { ...elem, comments: (elem?.comments || 0) + 1 };
              }
              return elem;
            }),
          }));
        }
      });
      setNewComment('');
    }
  };

  const onAvatarClick = () => {
    // navigateTo('123');
  };

  const handleFollow = (userId) => {
    return dispatch(
      socialAppFollowUser({
        userToFollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: true,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isFollowed: true,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: true };
            }
            return elem;
          }),
        }));
      }
    });
  };
  const handleUnFollow = (userId) => {
    return dispatch(
      socialAppUnFollowUser({
        userToUnfollow: userId,
      }),
    )?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isFollowed: false,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isFollowed: false,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isFollowed: false };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleEditComment = (comment) => {
    setEditingComment(comment);
    setEditCommentText(comment?.text?.toString());
  };

  const handleUpdateComment = async () => {
    if (editCommentText.trim() !== '') {
      dispatch(updateComment({ userId, commentId: editingComment._id, text: editCommentText })).then((res) => {
        if (res?.payload) {
          dispatch(getComments({ userId, postId }));
          setEditingComment(null);
          setEditCommentText('');
        }
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setEditCommentText('');
  };

  const handleDeleteComment = (commentId) => {
    dispatch(deleteComment({ userId, commentId })).then((res) => {
      if (res?.payload) {
        {
          dispatch(getComments({ userId, postId }));
          if (isUserPost) {
            setPostData((prev) => ({
              ...prev,
              comments: prev?.comments || 0 - 1,
            }));
            setPostDetails((prev) => ({
              ...prev,
              comments: prev?.comments || 0 - 1,
            }));
          }
          setAllPosts((prev) => ({
            ...prev,
            posts: prev?.posts?.map((elem) => {
              if (elem?._id === postDetails?._id) {
                elem = { ...elem, comments: (elem?.comments || 0) - 1 };
              }
              return elem;
            }),
          }));
        }
      }
    });
  };

  const sortedComments = comments.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  const postTypes = {
    generic_card: GenericPost,
    report_card: ReportCardPost,
    news_card: NewsCardPost,
    ad_card: AdCardPost,
    event_card: EventCardPost,
    lead_card: LeadCardPost,
    job_card: JobCardPost,
  };

  const PostComponent = postTypes?.[postDetails?.type];
  return (
    <div className="comment-modal-content">
      {isDashboardPost && (
        <div className="d-flex a-center mb-20">
          <LeftOutlined
            style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
            onClick={() => setPostDetails(null)}
          />
        </div>
      )}
      <div style={{ background: 'white', borderRadius: 10 }} className="modal-post p-20">
        <div className="post-left">
          <div>
            <PostComponent
              setPostDetails={setPostDetails}
              isViewPost={true}
              setAllposts={setAllPosts}
              setPostData={setPostData}
              handleFollow={handleFollow}
              handleUnFollow={handleUnFollow}
              postDetails={postDetails}
              isUserPost={isUserPost}
            />
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'end', marginLeft: '20px' }} className="post-right">
          <div className="d-flex g-10 jc-between a-center w-100">
            <Avatar
              className="avatar"
              onClick={onAvatarClick}
              src={''}
              icon={<UserOutlined />}
              style={{ alignContent: 'center' }}
            />
            <div className="comment-input-container" style={{ position: 'relative' }}>
              <InputEmoji
                value={newComment}
                shouldReturn
                onChange={(e, b) => {
                  setNewComment(e);
                }}
                maxLength={400}
                placeholder="Write a comment"
                // onEnter={handleOnEnter}
              />

              <Button
                style={{ borderRadius: '8px', color: '#4096ff', borderColor: '#4096ff', background: 'transparent' }}
                onClick={handleAddComment}
                ghost
              >
                Post
              </Button>
            </div>
          </div>
          <div className="comments-list">
            {loading && <p>Loading comments...</p>}
            {error && <p>{error}</p>}
            {sortedComments.length > 0 ? (
              sortedComments.map((comment, index) => (
                <div key={index} className="d-flex g-10">
                  <Avatar
                    className="avatar"
                    onClick={onAvatarClick}
                    src={comment?.userDetails?.personalDetails?.imageUrl}
                    icon={<UserOutlined />}
                    style={{ alignContent: 'center' }}
                  />
                  <div
                    style={{
                      backgroundColor: '#f2f2f2',
                      padding: '10px',
                      borderRadius: '10px',
                      flex: 2,
                    }}
                    key={comment._id}
                    className="comment-item"
                  >
                    <div className="comment-header">
                      <span className="font14 font700">{comment.userDetails.name}</span>
                      <span className="font12" style={{ color: '#888' }}>
                        {formatDistanceToNow(new Date(comment.createdAt), { addSuffix: true })}
                      </span>
                    </div>
                    <div className="font12" style={{ color: '#888' }}>
                      {comment.userDetails.professionalDetails.companyName}
                    </div>
                    {editingComment && editingComment._id === comment._id ? (
                      <div className="comment-edit-container">
                        <InputEmoji
                          maxLength={400}
                          shouldReturn
                          keepOpened
                          value={editCommentText}
                          onChange={setEditCommentText}
                          placeholder="Edit your comment"
                        />
                        <div className="comment-edit-buttons-container">
                          <Button
                            style={{
                              borderRadius: '8px',
                              color: '#4096ff',
                              borderColor: '#4096ff',
                              background: 'transparent',
                            }}
                            onClick={handleUpdateComment}
                            ghost
                          >
                            Save
                          </Button>
                          <Button
                            style={{
                              borderRadius: '8px',
                              color: '#ff4d4f',
                              borderColor: '#ff4d4f',
                              background: 'transparent',
                            }}
                            onClick={handleCancelEdit}
                            ghost
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="comment-text font14">
                        <span dangerouslySetInnerHTML={{ __html: comment.text }}></span>
                        {comment.userId === userId && (
                          <div id="comment-actions" className="comment-actions">
                            <EditOutlined
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleEditComment(comment)}
                              className="edit-button"
                            />
                            <DeleteOutlined onClick={() => handleDeleteComment(comment._id)} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p style={{ marginLeft: '48px' }}>No comments yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CommentComponent;
