import { DownloadOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import CommonButton from '../../../common/CommonButton';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import './leadCardPost.scss';

const LeadCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  return (
    <div className="report_card_post_content ">
      <div className="report_card_post_images">
        <div
          style={{
            background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${
              postDetails.imageUrls?.[0]?.url || defaultImage
            })
`,
          }}
          className="report-carousel-image"
        >
          <span className="postImageTitle">Contact Us</span>
        </div>
      </div>
      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={postDetails} />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>

          {/* <span>Designation: Sales Associate</span> */}
          <span>Engagement Type: {postDetails?.postDetails?.engagementType}</span>
          <span>City: {postDetails?.postDetails?.location}</span>
          <span>Is Open to Broker: {postDetails?.postDetails?.isOpenToBroker ? 'Yes' : 'No'}</span>
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default LeadCardPost;
