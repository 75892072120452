import React from 'react';
import Text from 'antd/es/typography/Text';
import CommonButton from '../../../common/CommonButton';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';
import DOMPurify from 'dompurify';

import './adCardPost.scss';
import PostHeader from '../../postHeader/PostHeader';
import PostFooter from '../../postFooter/PostFooter';

const AdCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails,
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  return (
    <div className="report_card_post_content ">
      <div className="news_card_post_audio_video">
        <img src={postDetails.imageUrls?.[0]?.url || defaultImage} />
      </div>
      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={postDetails} />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.synopsys) }}
          ></div>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>
          {postDetails?.postDetails?.CTA && (
            <CommonButton style={{ width: '90px' }} target="_blank" href={postDetails?.postDetails?.CTA?.link}>
              {postDetails?.postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default AdCardPost;
