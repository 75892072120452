import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Modal } from 'antd';
import React from 'react';

import Text from 'antd/es/typography/Text';
import { truncate } from 'lodash';
import CommentComponent from '../../../socialApp/posts/postFooter/CommentComponent';
import './postTypes.scss';

const LeadPost = ({
  data,
  postData,
  handleDeletPost,
  handleSeeMore,
  handleCancel,
  isVisiable,
  user,
  sanitizer,
  companyData,
  isCompany,
  setAllPosts,
  setPostData,
}) => {
  return (
    <div className="profile-layout" gutter={16}>
      <div className="imgcontainer position-relative">
        <img
          src={
            data?.imageUrls?.length > 0
              ? data?.imageUrls?.[0]?.url
              : 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai'
          }
          alt="Profile"
          className="profile-image"
        />
      </div>
      <div className="content-col" style={{ width: '100%' }}>
        <div className="d-flex jc-between g-10 " style={{ width: '100%' }}>
          <span className="d-flex g-15 a-center ">
            <Avatar src={user?.personalDetails?.imageUrl} icon={<UserOutlined />} />
            {isCompany ? (
              <div className="d-flex d-column">
                {companyData?.name && (
                  <span className="font14 fontExtraLight ">{user?.professionalDetails?.companyName}</span>
                )}
                {companyData?.address && (
                  <span className="font14 fontExtraLight ">
                    {companyData?.address?.city},{companyData?.address?.state}
                  </span>
                )}
              </div>
            ) : (
              <div className="d-flex d-column">
                <Text style={{ fontWeight: '600', color: '#535353' }}>{user?.name}</Text>
                <div className="d-flex">
                  {user?.professionalDetails?.designation && (
                    <Text
                      style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}
                    >{`${user?.professionalDetails?.designation} @`}</Text>
                  )}
                  {user?.professionalDetails?.companyName && (
                    <Text style={{ fontWeight: '600', fontSize: '12px', color: '#A2A2A2' }}>
                      {user?.professionalDetails?.companyName}
                    </Text>
                  )}
                </div>
              </div>
            )}
          </span>
          <div style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '16 px' }}>
            <DeleteOutlined onClick={handleDeletPost} />
          </div>
        </div>

        <div className="d-flex g-5 d-column  flex-2">
          <div
            id="post-body"
            style={{ fontWeight: '600', color: '#535353' }}
            dangerouslySetInnerHTML={{ __html: sanitizer(truncate(data?.postDetails?.body, { length: 142 })) }}
          ></div>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> Engagement Type :</span> {data?.postDetails?.engagementType}
          </span>
          <span className="font14 postBody fontExtraLight">
            <span className="fontt600 fontLight"> City :</span> {data?.postDetails?.location}
          </span>
        </div>

        <Text className="cursor-pointer" style={{ fontWeight: '600', color: '#0081FC' }} onClick={handleSeeMore}>
          See more
        </Text>
      </div>
      {postData && (
        <Modal
          styles={{
            mask: {
              backdropFilter: 'blur(6px)',
            },
          }}
          centered
          title={null}
          open={isVisiable}
          onCancel={handleCancel}
          footer={null}
          width={820}
        >
          <CommentComponent
            isUserPost={true}
            setAllPosts={setAllPosts}
            setPostData={setPostData}
            postDetails={postData}
            postId={postData?._id}
            userId={user?._id}
          />
        </Modal>
      )}
    </div>
  );
};

export default LeadPost;
