import React from 'react';

const NewsCardImageComponentMyAccount = ({ postDetails }) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  return (
    <div className="imgcontainer position-relative">
      <img
        src={postDetails?.imageUrls?.length > 0 ? postDetails?.imageUrls?.[0]?.url : defaultImage}
        alt="Profile"
        className="profile-image"
      />
    </div>
  );
};

export default NewsCardImageComponentMyAccount;
