import { Divider, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommentIcon from '../../../../../assets/SocialApp/CommentIcon';
import LikeIcon from '../../../../../assets/SocialApp/LikeIcon';
import SavedIcon from '../../../../../layout/home/images/SavedIcon';
import { updateActivity } from '../../../../features/socialAppSlice';
import './postFooter.scss';
import SaveIcon from '../../../../../assets/SocialApp/SaveIcon';

const PostFooter = ({
  isViewPost,
  setPostDetails = () => {},
  postDetails,
  setPostData,
  isUserPost,
  setAllPosts,
  allPostsPage = false,
}) => {
  const userId = useSelector((state) => state.user?.user?.id);
  const dispatch = useDispatch();
  const handleLike = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'likes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: true,
            likes: (prev?.likes || 0) + 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isLiked: true,
            likes: (prev?.likes || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: true, likes: (elem?.likes || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleUnLike = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unlikes' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isLiked: false,
            likes: (prev?.likes || 0) - 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isLiked: false,
            likes: (prev?.likes || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id === postDetails?._id) {
              elem = { ...elem, isLiked: false, likes: elem?.likes - 1 };
            }
            return elem;
          }),
        }));
      }
    });

    // dispatch(getComments({ userId: userId, postId: postDetails?._id }));
  };

  const handleSave = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'saves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: true,
            saves: (prev?.saves || 0) + 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isSaved: true,
            saves: (prev?.saves || 0) + 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: true, saves: (elem?.saves || 0) + 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  const handleUnSave = () => {
    dispatch(updateActivity({ userId: userId, postId: postDetails?._id, action: 'unsaves' }))?.then((res) => {
      if (res?.payload) {
        if (isUserPost) {
          setPostData((prev) => ({
            ...prev,
            isSaved: false,
            saves: (prev?.saves || 0) - 1,
          }));
          setPostDetails((prev) => ({
            ...prev,
            isSaved: false,
            saves: (prev?.saves || 0) - 1,
          }));
        }
        setAllPosts((prev) => ({
          ...prev,
          posts: prev?.posts?.map((elem) => {
            if (elem?._id == postDetails?._id) {
              elem = { ...elem, isSaved: false, saves: elem?.saves - 1 };
            }
            return elem;
          }),
        }));
      }
    });
  };

  return (
    <div className="d-flex d-column">
      <div className="d-flex jc-between p-5">
        <span className="d-flex jc-between g-20 footerIcons">
          <Tooltip title={'Like'}>
            <span className="d-flex a-center cursor-pointer" onClick={postDetails?.isLiked ? handleUnLike : handleLike}>
              <LikeIcon color={postDetails?.isLiked ? '#0081FC' : '#aaa9a9'} />
            </span>
          </Tooltip>
          <Tooltip title="Comment">
            <span
              className="d-flex a-center cursor-pointer"
              onClick={() => {
                if (allPostsPage && !isViewPost) setPostDetails(postDetails);
              }}
              style={allPostsPage ? { cursor: 'pointer' } : { cursor: 'auto' }}
            >
              <CommentIcon />
            </span>
          </Tooltip>
        </span>
        <span className="d-flex a-center cursor-pointer" onClick={postDetails?.isSaved ? handleUnSave : handleSave}>
          {/* <SavedIcon strokeColor={postDetails?.isSaved ? '#1677ff' : 'black'} /> */}
          <SaveIcon color={postDetails?.isSaved ? '#0081FC' : '#aaa9a9'} />
        </span>
      </div>
      <Divider className="footerDivider" />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '12px',
          color: '#cbcbcb',
          marginTop: '5px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
          }}
        >
          <span>{postDetails.likes || 0} Likes</span>
          <span>
            <li>{postDetails.comments || 0} Comments</li>
          </span>
        </div>
        <span>{postDetails.saves || 0} Saves</span>
      </div>
    </div>
  );
};

export default PostFooter;
