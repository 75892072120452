import { FileOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, DatePicker, Flex, Form, Input, Radio, Row, Spin, TimePicker, Typography, Upload, message } from 'antd';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';
import SelectUploadedImages from '../SelectUploadedImages';
import ReactQuillComponent from '../common/ReactQuillComponent';
import { formatUTCTimeWithAmPm } from '../../../../../../utils/helpers';
// import './genericPostForm.scss';

const EventCardForm = ({
  setShowForm = () => {},
  setAllposts,
  closePopup = () => {},
  setActiveTab = () => {},
  isCompany,
  postRel = 'user_post',
  isPostFromMyAccount = false,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  // form derails
  const [formDetails] = Form.useForm();

  // states

  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const userId = useSelector((state) => state.user?.user?.id);
  const userv2 = useSelector((state) => state.user?.userV2);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);

  // handle image upload
  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    setImageloader(true);
    dispatch(uploadPostImage(formData))?.then((res) => {
      setImageloader(false);
      message.success(`${file?.name} uploaded successfully...`);
      if (res?.payload?.data?.response?.data) {
        setImgNames((prev) => [...(prev || []), file?.name]);
        setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
      }
    });
  };

  // handle submit
  const handleFormSubmit = (values) => {
    console.log('values', values);

    const createPostPayload = {
      title: values?.post_title || '',
      type: 'event_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      postDetails: {
        body: values?.post_caption || '',
        type: values?.event_type || '',
        dateTime: {
          date: new Date(values?.date).toUTCString(),
          time: formatUTCTimeWithAmPm(new Date(values?.time)),
        },
        organiser: values?.event_organiser || '',
        timeZone: values?.time_zone || '',
        link: values?.event_url || '',
        speaker: values?.speaker || '',
      },
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        closePopup();
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        message.success('Post created successfully...');
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'event_card',
            title: values?.post_title || '',
            body: values?.post_caption || '',
            ownerId: userId,
            ownerType: 'user_post',
            likes: 0,
            comments: 0,
            imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
            isOwner: true,
            postDetails: createPostPayload.postDetails,
            ownerDetails: {
              _id: userId,
              name: userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));
        }
      } else {
        message.error('Some error occurred...');
      }
    });
  };
  //JSX
  return (
    <div className="genericpostform">
      {!isPostFromMyAccount && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark">Event Card</Text>
          </div>

          <div>
            <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
              Reset
            </CommonButton>
            <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
              Create Post
            </CommonButton>
          </div>
        </div>
      )}
      <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
        <div className="mt-10">
          <div className={`d-flex ${isPostFromMyAccount && 'd-column'} `}>
            <div className={`imageContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item name="imageURL" className="dropdown-form-item">
                <div className="d-flex g-20" style={{ marginBottom: '20px' }}>
                  <Upload
                    listType="picture-card"
                    className="uploadImage"
                    multiple
                    accept=".png,.jpeg,.jpg"
                    customRequest={({ file }) => handleUpload(file)}
                    showUploadList={false}
                  >
                    {imgLoader ? (
                      <Spin indicator={<LoadingOutlined />} />
                    ) : (
                      <>
                        <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Image</Text>
                      </>
                    )}
                  </Upload>
                </div>
                {/* ----Uploadeed images------ */}
                {imgNames?.length > 0 && (
                  <div style={{ maxHeight: '125px', overflow: 'scroll' }}>
                    {imgNames?.map((elem, index) => (
                      <div className="fontBlue" key={index}>
                        <FileOutlined /> {elem}
                      </div>
                    ))}
                  </div>
                )}
                {/* ----Uploadeed images------ */}
              </Form.Item>
              <Form.Item required label="" className="dropdown-form-item">
                <Form.Item name="imageURL">
                  <SelectUploadedImages imageUrl={imgUrls} setImgUrl={setImgUrls} setImageNames={setImgNames} />
                </Form.Item>
              </Form.Item>
            </div>
            <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="post_title"
                required
                label="Enter Event Title"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Post Title!',
                  },
                ]}
              >
                <Input placeholder="Add Headline" min={0} />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="event_type"
                    label="Event Type"
                    className="dropdown-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Select Event Type!',
                      },
                    ]}
                  >
                    <Radio.Group>
                      <Radio value={'online'}>Online</Radio>
                      <Radio value={'offline'}>Offline</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="event_organiser"
                    required
                    label="Event organiser"
                    className="dropdown-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Add Event Organiser!',
                      },
                    ]}
                  >
                    <Input placeholder="Organiser" min={0} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="post_caption"
                required
                label="Enter Event Description"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Description!',
                  },
                ]}
              >
                {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                <ReactQuillComponent />
                {/* <TextArea placeholder="Write Description here" style={{ width: '470px', height: 80 }} min={0} /> */}
              </Form.Item>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="time_zone"
                    required
                    label="Time Zone"
                    className="dropdown-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Add Time Zone!',
                      },
                    ]}
                  >
                    <Input placeholder="IST" min={0} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item required label="Date" className="dropdown-form-item">
                    <Flex gap="middle" horizontal>
                      <Form.Item
                        name="date"
                        rules={[
                          {
                            required: true,
                            message: 'Select Date!',
                          },
                        ]}
                      >
                        <DatePicker placeholder="DD/MM/YYYY" style={{ width: '100%' }} />
                      </Form.Item>
                    </Flex>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item required label="Time" className="dropdown-form-item">
                    <Form.Item
                      name="time"
                      rules={[
                        {
                          required: true,
                          message: 'Select Time!',
                        },
                      ]}
                    >
                      <TimePicker use12Hours format="h:mm a" />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="event_url"
                required
                label="Event URL"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Add Event URL!',
                  },
                ]}
              >
                <Input placeholder="Enter Event URL" min={0} />
              </Form.Item>

              <Form.Item name="speaker" label="Speaker" className="dropdown-form-item">
                <Input min={0} />
              </Form.Item>
            </div>
          </div>
        </div>
        {isPostFromMyAccount && (
          <div className="d-flex a-center jc-end g-10">
            <div>
              <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default EventCardForm;
