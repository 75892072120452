import React from 'react';
import './employeeDetails.scss';
import connectBG from '../../../../../assets/images/connectBG.png';
import { isEmpty } from 'lodash';
import ConnectDetails from './ConnectDetails';
import animationData from '../../../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../../../universal/components/lottieAnimation/LottieAnimation';

const EmployeesDetails = ({ loader }) => {
  return (
    <div className="connect_container  d-flex d-column">
      <div className="font14 font900 fontLight-3 mb-10">Employee of Selected Company</div>
      <div className={`employeeDetails ${loader && 'border-light-2'}`}>
        {/* {isEmpty(connectsData) && !loader ? (
          <img src={connectBG} />
        ) : (
          <ConnectDetails loader={loader} connectsData={connectsData} />
        )} */}
        <div className="employe-imgdiv">
          <LottieAnimation height={150} width={150} animationData={animationData} loop={true} />
          <span className="bold employe-imgdiv__text">
            We are finding right<br></br> <span className="bold text-middlecard">CONNECTS</span>
            <br></br>
            For you, Be with us !
          </span>
        </div>
      </div>
    </div>
  );
};

export default EmployeesDetails;
