import { FileOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Col, Flex, Form, Input, Radio, Row, Space, Spin, Typography, Upload, message } from 'antd';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';
import SelectUploadedImages from '../SelectUploadedImages';
import ReactQuillComponent from '../common/ReactQuillComponent';
import './requirementPostForm.scss';
import { engagementType, leadTypes } from './requirementForm.utils';
import OpenToBrokerForm from './OpenToBrokerForm';
import Location from './Location';
const RequirementCardForm = ({
  setShowForm = () => {},
  setAllposts,
  closePopup = () => {},
  setActiveTab = () => {},
  isCompany,
  postRel = 'user_post',
  isPostFromMyAccount = false,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  // form derails
  const [formDetails] = Form.useForm();

  // states

  const [loader, setLoader] = useState(false);
  const userId = useSelector((state) => state.user?.user?.id);
  const userv2 = useSelector((state) => state.user?.userV2);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);

  // default image
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';

  // handle submit
  const handleFormSubmit = (values) => {
    console.log('values', values);

    const createPostPayload = {
      title: values?.post_title || '-',
      type: 'lead_card',
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
      imageUrls: [{ url: defaultImage, type: 'image' }],
      postDetails: {
        body: values?.post_caption || '',
        leadType: values?.lead_type || '',
        engagementType: values?.engagement_type || '',
        location: values?.location[0]?.value || [],
        isOpenToBroker: values?.seller_open_to_broker[0] === 'Yes' || false,
        budgetRange: {
          lowerLimit: Number(values?.buyer_budgetRange_lower_limit) || 0,
          higherLimit: Number(values?.buyer_budgetRange_upper_limit) || 0,
        },
      },
    };

    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        closePopup();
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        message.success('Post created successfully...');
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'lead_card',
            title: values?.post_title || '',
            body: values?.post_caption || '',
            ownerId: userId,
            ownerType: 'user_post',
            likes: 0,
            comments: 0,
            imageUrls: [{ url: defaultImage, type: 'image' }],
            isOwner: true,
            postDetails: createPostPayload.postDetails,
            ownerDetails: {
              _id: userId,
              name: userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));
        }
      } else {
        message.error('Some error occurred...');
      }
    });
  };

  //JSX
  return (
    <div className="genericpostform">
      {!isPostFromMyAccount && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark">Lead Card</Text>
          </div>

          <div>
            <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
              Reset
            </CommonButton>
            <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
              Create Post
            </CommonButton>
          </div>
        </div>
      )}
      <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
        <div className="mt-10">
          <div className={`d-flex ${isPostFromMyAccount && 'd-column'} `}>
            <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="lead_type"
                label="Select your Lead Type"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Select Lead Type!',
                  },
                ]}
              >
                <Radio.Group>
                  {leadTypes.map((val) => (
                    <Radio value={val.label}>{val.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Form.Item
                name="engagement_type"
                label="Select type of engagement"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Select Lead Type!',
                  },
                ]}
              >
                <Radio.Group>
                  {engagementType.map((val) => (
                    <Radio value={val.label}>{val.label}</Radio>
                  ))}
                </Radio.Group>
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="post_caption"
                    required
                    label="Description"
                    className="dropdown-form-item"
                    rules={[
                      {
                        required: true,
                        message: 'Add Description!',
                      },
                    ]}
                  >
                    {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                    <ReactQuillComponent />
                    {/* <TextArea placeholder="Write Description here" style={{ width: '470px', height: 80 }} min={0} /> */}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Location placeholder="Enter Preferred Location" label="" name="location" />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Budget Range" className="dropdown-form-item">
                    <Form.Item
                      name="buyer_budgetRange_lower_limit"
                      required
                      label="Lower limit"
                      dependencies={['buyer_budgetRange_upper_limit']}
                      className="dropdown-form-item"
                      rules={[
                        {
                          required: true,
                          message: 'Select Budget Range',
                        },
                      ]}
                    >
                      <Input type="number" placeholder="Rs. 0.00" style={{ width: '150px' }} min={0} />
                    </Form.Item>
                    <Form.Item
                      label="Upper limit"
                      className="dropdown-form-item"
                      name="buyer_budgetRange_upper_limit"
                      dependencies={['buyer_budgetRange_lower_limit']}
                      rules={[
                        {
                          required: true,
                          message: 'Select Budget Range',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const lowerLimit = getFieldValue('buyer_budgetRange_lower_limit');
                            const isUpperLimitField = true; // Assuming this is the Upper Limit field
                            if (
                              value &&
                              lowerLimit &&
                              parseFloat(value) <= parseFloat(lowerLimit) &&
                              isUpperLimitField
                            ) {
                              return Promise.reject('Upper limit must be greater than lower limit');
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input type="number" placeholder="Rs. 0.00" style={{ width: '150px' }} min={0} />
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item className="dropdown-form-item">
                    <Space className="sellercontainer-form__space"></Space>
                    <Text className="sellercontainer-form__space--subTitle">Open to brokers?</Text>
                    <Form.Item name="seller_open_to_broker">
                      <OpenToBrokerForm />
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {isPostFromMyAccount && (
          <div className="d-flex a-center jc-end g-10">
            <div>
              <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
  //   return <>Hi</>;
};

export default RequirementCardForm;
