import React from 'react';

const AdvPostIcon = () => {
  return (
    <svg
      id="Ad_Card"
      data-name="Ad Card"
      xmlns="http://www.w3.org/2000/svg"
      width="22.179"
      height="27"
      viewBox="0 0 22.179 27"
    >
      <g id="g1517" transform="translate(0 0)">
        <g id="path1519" transform="translate(0)">
          <path
            id="Path_17677"
            data-name="Path 17677"
            d="M19.547,43c-.265,0-.48-.289-.48-.644V37.028H17.974v5.326c0,.355-.216.644-.48.644s-.48-.289-.48-.644V37.028H7.91c-.265,0-.48-.289-.48-.644s.216-.644.48-.644h2.257v-.548H8.936c-.453,0-.822-.495-.822-1.1V21.236c0-.609.369-1.1.822-1.1h3.97v-.548h-.2c-.265,0-.48-.289-.48-.644V17.562c0-.861.522-1.562,1.165-1.562s1.165.7,1.165,1.562v1.377c0,.355-.216.644-.48.644h-.2v.548h4.173v-.548h-.2c-.265,0-.48-.289-.48-.644V17.562c0-.861.522-1.562,1.165-1.562s1.165.7,1.165,1.562v1.377c0,.355-.216.644-.48.644H19v.548h4.174v-.548h-.2c-.265,0-.48-.289-.48-.644V17.562c0-.861.522-1.562,1.165-1.562s1.165.7,1.165,1.562v1.377c0,.355-.216.644-.48.644h-.2v.548H28.1c.454,0,.823.495.823,1.1V34.091c0,.607-.369,1.1-.823,1.1H20.025v.548h9.1c.265,0,.48.289.48.644s-.216.644-.48.644h-9.1v5.326c0,.355-.216.644-.48.644Zm-.48-7.257v-.548H17.974v.548Zm-2.054,0v-.548H12.7c-.265,0-.48-.289-.48-.644s.216-.644.48-.644H27.966V21.421H9.075V33.906h2.257c.265,0,.48.289.48.644s-.216.644-.48.644h-.2v.548h5.886ZM23.859,18.3v-.734c0-.151-.091-.273-.2-.273s-.2.122-.2.273V18.3Zm-5.135,0v-.734c0-.151-.091-.273-.2-.273s-.2.122-.2.273V18.3h.409Zm-5.134,0v-.734c0-.151-.091-.273-.2-.273s-.2.122-.2.273V18.3Z"
            transform="translate(-7.43 -16)"
            fill="#b4b4b4"
          />
        </g>
      </g>
      <g id="g1565" transform="translate(5.133 8.6)">
        <g id="path1567" transform="translate(0 0)">
          <path
            id="Path_17678"
            data-name="Path 17678"
            d="M55.549,70.322a.48.48,0,0,1-.441-.291l-.316-.736H51.514l-.315.736a.48.48,0,0,1-.883-.377l1.919-4.478a1,1,0,0,1,1.837,0l1.918,4.478a.48.48,0,0,1-.253.629.483.483,0,0,1-.189.04Zm-1.168-1.988-1.192-2.781a.038.038,0,0,0-.036-.023.039.039,0,0,0-.036.024l-1.191,2.78h2.456Z"
            transform="translate(-50.277 -64.57)"
            fill="#b4b4b4"
          />
        </g>
      </g>
      <g id="g1569" transform="translate(11.637 8.599)">
        <g id="path1571">
          <path
            id="Path_17679"
            data-name="Path 17679"
            d="M105.04,70.313a.481.481,0,0,1-.48-.48V66.41a.48.48,0,1,1,.961,0v2.942h1.232a1.916,1.916,0,0,0,0-3.831H105.04a.48.48,0,1,1,0-.961h1.712a2.877,2.877,0,0,1,0,5.753Z"
            transform="translate(-104.56 -64.56)"
            fill="#b4b4b4"
          />
        </g>
      </g>
    </svg>
  );
};

export default AdvPostIcon;
