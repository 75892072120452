import React, { useState, useCallback } from 'react';
import './eventCardImageCarousel.scss';

const EventCardImageCarousel = ({ imageUrls = [], className, postDetails }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1));
  }, [imageUrls.length]);

  const goToNext = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex === imageUrls.length - 1 ? 0 : prevIndex + 1));
  }, [imageUrls.length]);

  const goToIndex = useCallback((index) => {
    setCurrentIndex(index);
  }, []);

  return (
    <div className={`carousel ${className}`}>
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)`, height: 'inherit' }}>
        {imageUrls.map((url, index) => (
          <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
            <div className="report_card_post_images">
              <div
                style={{
                  background: `
            linear-gradient(0deg, rgba(0, 0, 0, 6.5), rgba(255, 255, 255, 0.3)), url(${url})
`,
                }}
                className="report-carousel-image"
              >
                <span className="postImageTitle">{postDetails?.title}</span>
                <span className="pagePostType">Event Type - {postDetails?.postDetails?.type}</span>
                <span className="pagePostTimeZone">Timezone: {postDetails?.postDetails?.timeZone}</span>
                <span className="linktitle">
                  Online link -
                  <a href={postDetails?.postDetails?.link} target='_blank'>
                    <span className="link">{postDetails?.postDetails?.link}</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {imageUrls.length > 1 && (
        <div className="carousel-dots">
          {imageUrls.map((url, index) => (
            <button
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => goToIndex(index)}
            ></button>
          ))}
        </div>
      )}
      {imageUrls.length > 1 && currentIndex > 0 && (
        <button className="carousel-control prev" onClick={goToPrevious}>
          &#10094;
        </button>
      )}
      {imageUrls.length > 1 && currentIndex < imageUrls.length - 1 && (
        <button className="carousel-control next" onClick={goToNext}>
          &#10095;
        </button>
      )}
    </div>
  );
};

export default EventCardImageCarousel;
