// Css Imports
import { Card, Collapse, Divider, Empty, Tooltip } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import genIcon from '../../../assets/LeadGen.png';
import leadImage from '../../../assets/SocialApp/leadImage.png';
import './socialApp.scss';
// React,React-Redux,Router Imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty, truncate } from 'lodash';
import AnnouncementsCardDashboard from '../../../leadgen/components/announcementsCardDashboard/AnnouncementsCardDashboard';
import AddPost from '../../components/socialApp/posts/addPost/AddPost';
import GenericPostForm from '../../components/socialApp/posts/addPostForms/GenericPostForm/GenericPostForm';
import NewsPostForm from '../../components/socialApp/posts/addPostForms/NewsPostForm/NewsPostForm';
import ReportPostForm from '../../components/socialApp/posts/addPostForms/ReportsPostForm/ReportsPostForm';
import AllPosts from '../../components/socialApp/posts/allPosts/AllPosts';
import CommentComponent from '../../components/socialApp/posts/postFooter/CommentComponent';
import { getSocialDashbordPosts, setSearchVisibleSocialApp } from '../../features/socialAppSlice';
import { fetchAccouncementsData, fetchRecentActivities } from '../../features/userDashboardSlice';
import { useWindowSize } from '../../../utils/useWindowSize/useWindowSize';
import ImageCarousel from '../../components/socialApp/posts/allPosts/common/imageCarousel/ImageCarousel';
import LeadCards from '../../components/socialApp/leadCard/LeadCard';
import { fetchUserProfileDataV2 } from '../../features/myAccountSlice';
import AdvertisementPostForm from '../../components/socialApp/posts/addPostForms/AdvertisementPostForm/AdvertisementPostForm';
import EventCardForm from '../../components/socialApp/posts/addPostForms/EventCardForm/EventCardForm';
import RequirementCardForm from '../../components/socialApp/posts/addPostForms/RequirementCardForm/RequirementCardForm';
import JobPostForm from '../../components/socialApp/posts/addPostForms/JobPostForm/JobPostForm';
// Slices and reducer imports

// Component Imports

const SocialApp = () => {
  const { recentActivities } = useSelector((store) => store.userDashboard);
  const { announcements } = useSelector((store) => store.userDashboard);

  const { isAbove1920 } = useWindowSize();
  // Hooks
  const dispatch = useDispatch();
  const { Panel } = Collapse;

  // // States
  const limit = 10;

  const [showPostForm, setShowPostForm] = useState(null);
  const [offset, setOffset] = useState(0);
  const [allPosts, setAllposts] = useState([]);
  const [postDetails, setPostDetails] = useState(null);
  const [showCreatePostPopup, setShowCreatePopup] = useState(false);
  const { user } = useSelector((store) => store.user);

  // fetching on mounting
  useEffect(() => {
    dispatch(fetchUserProfileDataV2());
    setOffset(0);
    dispatch(setSearchVisibleSocialApp(true));
    dispatch(fetchRecentActivities());
    dispatch(fetchAccouncementsData());
    dispatch(
      getSocialDashbordPosts({
        offset: offset,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts(res?.payload?.data?.response?.data);
      }
    });
    return () => dispatch(setSearchVisibleSocialApp(false));
  }, []);
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';

  // handleing infinite scroll
  const handleScroll = () => {
    setOffset((prev) => prev + limit);
    dispatch(
      getSocialDashbordPosts({
        offset: offset + limit,
        limit: limit,
      }),
    )?.then((res) => {
      if (res?.payload?.data?.response?.data) {
        setAllposts((prev) => {
          return {
            ...prev,
            posts: [...(prev?.posts || []), ...(res?.payload?.data?.response?.data?.posts || [])],
            totalRecords: res?.payload?.data?.response?.data?.totalRecords,
          };
        });
      }
    });
  };

  const formMaps = {
    GENERIC_POST: GenericPostForm,
    REPORT_CARD: ReportPostForm,
    NEWS_CARD: NewsPostForm,
    Ad_CARD: AdvertisementPostForm,
    EVENT_CARD: EventCardForm,
    LEAD_CARD: RequirementCardForm,
    JOB_CARD: JobPostForm,
  };

  const FormComponent = formMaps?.[showPostForm];

  return (
    <div className="socialAppContainer">
      <div className="socialAppContainer__postContainer">
        {/* -----Add post------ */}

        {!showPostForm && !postDetails && (
          <AddPost
            showPostForm={showPostForm}
            setShowPostForm={setShowPostForm}
            imageUrl={user?.personalDetails?.imageUrl}
            setShowCreatePopup={setShowCreatePopup}
          />
        )}

        {/* -----Add post------ */}

        {/* -----ALl post------ */}
        {/* <AllPosts setAllposts={setAllposts} handleScroll={handleScroll} allPosts={allPosts} /> */}
        {/* <RenderPostFormsAndDetails /> */}
        {postDetails ? (
          <CommentComponent
            isDashboardPost={true}
            setAllPosts={setAllposts}
            userId={user?.id}
            isUserPost={true}
            postId={postDetails?._id}
            postDetails={postDetails}
            setPostDetails={setPostDetails}
          />
        ) : showPostForm ? (
          <FormComponent postRel="user_post" setAllposts={setAllposts} setShowForm={setShowPostForm} />
        ) : (
          <AllPosts
            setPostDetails={setPostDetails}
            setAllposts={setAllposts}
            handleScroll={handleScroll}
            allPosts={allPosts}
          />
        )}
        {/* -----ALl post------ */}

        <div className="socialAppContainer__postContainer--post"></div>
      </div>

      <div style={{ flex: 1, overflow: 'scroll' }}>
        <div className="socialAppContainer__rightContainer">
          <span className="font18 font500 mb-10">My Leads</span>
          <div style={{ flex: 3, padding: 0 }}>
            <LeadCards />
          </div>
        </div>
        <Card className="mt-10 mb-10 d-flex d-column h-100  tryThatAddCard" style={{ height: 400 }}>
          <div className="d-flex a-center jc-center h-100">
            {' '}
            <img src={defaultImage} />
          </div>
        </Card>
        {!isAbove1920 && (
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group"></Panel>
            <Panel header="Event"></Panel>
          </Collapse>
        )}
      </div>
      {isAbove1920 && (
        <div style={{ flex: 1, overflow: 'scroll' }}>
          <Collapse expandIconPosition={'end'} bordered={false}>
            <Panel header="Announcements">
              <div
                style={{ overflow: 'scroll', height: 400 }}
                className="socialAppContainer__rightContainer--insideContainer"
              >
                {isEmpty(announcements) ? (
                  <Empty className="announcement-desc" description="No Announcements" />
                ) : (
                  announcements?.map((el, i) => {
                    return <AnnouncementsCardDashboard data={el} key={i} />;
                  })
                )}
              </div>
            </Panel>
            <Panel header="Recent Activity">
              <div className="userdashboard-activity">
                {recentActivities?.length > 0 ? (
                  recentActivities?.map((el, index) => {
                    return (
                      <div key={index}>
                        <Divider />
                        <div className="userDashboard__activity-item">
                          <div className="activity-item__action">
                            <div style={{ marginRight: '10px' }} className="userDashboard__activity-icon">
                              <img src={genIcon} alt="" width={30} />
                            </div>
                            <span className="userDashboard__activity-message">
                              <Tooltip title={el.message}>{truncate(el.message, { length: 40 })}</Tooltip>
                            </span>
                          </div>

                          <span className="userDashboard__activity-time">
                            {dayjs(el?.createdAt).format('DD-MM-YYYY hh:mm')}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <span>No Recent Activity</span>
                )}
              </div>
            </Panel>
            <Panel header="Group"></Panel>
            <Panel header="Event"></Panel>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default SocialApp;
