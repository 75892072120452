import { BellOutlined, CheckSquareOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Input, List, Menu, Popover, Progress, Space, Switch, Typography } from 'antd';
import { Header } from 'antd/es/layout/layout';
import { useNavigate } from 'react-router';
import logo from '../../../../assets/logo.svg';
// styles
import { nanoid } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import {
  getSearchResults,
  reset_data,
  setPagination,
  setSearchContext,
  setSelectedOrganizationId,
  setpropertyToggle,
} from '../../../../leadgen/features/searchSlice';
import { fetchUserProfileCompletion } from '../../../../universal/features/myAccountSlice';
import { fetchUserPoints } from '../../../../universal/features/userOnboardingSlice';
import { getAllNotifications, markAsreadNotification } from '../../../../universal/features/userSlice';
import { useWindowSize } from '../../../../utils/useWindowSize/useWindowSize';
import './topNav.scss';

import PropertyTypeToggle from './PropertyTypeToggle';
import { commericalPropertyRadios, residentialPropertyRadios } from './topNav.utils';
import { setSelectedNav } from '../../../../leadgen/features/universalSidenavSlice';
import { persistor } from '../../../../redux/store'; // Import persistor if it's not imported yet
import MyAccountIcon from '../../../home/images/MyAccount';
import storeImg from '../../../../assets/images/store-icon.svg';
import LogoutIcon from '../../../../assets/images/logout2.svg';

const TopNav = () => {
  const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

  const [open, setOpen] = useState(false);
  const [notificationContent, setNotificationContent] = useState();
  const [searchQuery, setSearchQuery] = useState('');
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const { userPoints } = useSelector((store) => store.userOnboarding);
  const { user, universalNotifications, profileCompletion } = useSelector((store) => store.user);
  const { Text } = Typography;
  const { propertyToggle, searchContext } = useSelector((store) => store.search);
  const { isAbove1320, isAbove1920 } = useWindowSize();
  const [params, setSearchParams] = useSearchParams();
  const { selectedNav } = useSelector((store) => store.universalSideNav);
  const searchQueries = params?.get('searchQuery');
  useEffect(() => {
    dispatch(getAllNotifications());
    dispatch(fetchUserPoints());
    dispatch(fetchUserProfileCompletion());
  }, [dispatch]);

  useEffect(() => {
    setNotificationContent(getContent());
  }, [universalNotifications]);
  const getContent = useCallback(() => {
    return (
      <List
        className="topnav-items"
        dataSource={universalNotifications}
        // loading="true"
        renderItem={(item) => (
          <List.Item
            key={item?._id}
            style={{ background: item.status === false ? '#DEF0FF' : 'white', padding: '10px' }}
          >
            <List.Item.Meta title={item?.title} description={item?.description} />
            <div className="items-desc">
              <Text className="items-text">{format(item?.createdAt, 'dd/mm/yyyy hh:mm:ss')}</Text>
              <Text
                style={{
                  fontSize: '10px',
                  visibility: item?.status === false ? 'visible' : 'hidden',
                  cursor: 'pointer',
                  color: '#003FAB',
                }}
                onClick={() => {
                  dispatch(markAsreadNotification(item?._id));
                }}
              >
                mark as read
              </Text>
            </div>
          </List.Item>
        )}
      />
    );
  }, [dispatch, universalNotifications]);

  useEffect(() => {
    setNotificationContent(getContent());
  }, [getContent, universalNotifications]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const getUnreadNotificationCount = () => {
    if (isEmpty(universalNotifications)) return 0;
    return universalNotifications?.reduce((count, obj) => {
      if (obj.status === false) {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  };
  const logout = async () => {
    dispatch(setSelectedNav('Logout'));
    setLoading(true);
    await persistor.purge();
    await persistor.flush();

    localStorage.clear();
    await delay(1000);

    setLoading(false);

    window.location.replace('/');
  };

  const searchParam = useCallback(
    (searchQuery) => {
      dispatch(
        getSearchResults({
          body: {
            query: `${searchQuery}`,
            offset: 0,
            limit: 10,
          },
        }),
      )?.then((res) => {
        if (res?.payload?.data) {
        }
      });

      dispatch(setPagination({ offset: 0, limit: 10 }));
    },
    [dispatch],
  );
  // Search params

  useEffect(() => {
    if (searchQueries) {
      setSearchQuery(searchQueries);
      searchParam(searchQueries);
    }
  }, [searchQueries]);

  // Render
  const handleMenuClick = (e) => {
    if (e.key === 'myaccount') {
      navigateTo('/user/myaccount');
      dispatch(setSelectedNav('MyAccount'));
    } else if (e.key === 'Store') {
      if (profileCompletion?.overallCompletion >= 80) {
        navigateTo('/user/store');
      }
    } else if (e.key === 'logout') {
      logout();
      dispatch(setSelectedNav(false));
    }
  };
  const profileMenu = (
    <Menu
      onClick={handleMenuClick}
      style={{
        backgroundColor: '#fff',
        padding: '17px',
        marginTop: '5px',
        display: 'flex',
        gap: '8px',
        flexDirection: 'column',
        marginRight: '-7px',
      }}
    >
      <Menu.Item
        key="myaccount"
        icon={<MyAccountIcon strokeColor={selectedNav === 'MyAccount' ? '#FFA300' : '#000'} />}
        disabled={profileCompletion?.overallCompletion <= 80}
      >
        <span style={{ color: selectedNav === 'MyAccount' ? '#FFA300' : '#000', marginLeft: '12px', fontSize: '16px' }}>
          Account Details
        </span>
      </Menu.Item>
      <Menu.Item key="Store">
        <img style={{ width: '29px' }} src={storeImg} />
        <span style={{ marginLeft: '12px', fontSize: '16px', color: '#000', position: 'absolute', marginTop: '4px' }}>
          Store
        </span>
      </Menu.Item>
      <Menu.Item key="logout">
        <img style={{ width: '29px' }} src={LogoutIcon} />
        <span style={{ marginLeft: '12px', fontSize: '16px', color: '#000', position: 'absolute', marginTop: '4px' }}>
          Logout
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header className="header">
      <div className="header__items">
        <div className="header__logo">
          <img
            src={logo}
            alt="logo"
            width="150px"
            className="header__logo-img"
            onClick={() => navigateTo('/leadgen/dashboard')}
          />
        </div>
        {
          <div
            style={{ display: 'flex', flexDirection: 'row-reverse', marginLeft: '-70px' }}
            className={isAbove1920 ? 'topNavBarAbove1920' : ''}
          >
            <Space size={'large'}>
              {isAbove1920 && (
                <Switch
                  className="topNavSwitch"
                  style={{ marginLeft: '22px' }}
                  checkedChildren={
                    <span style={{ paddingRight: 10 }} className=" p-10">
                      Commercial
                    </span>
                  }
                  unCheckedChildren={<span style={{ paddingRight: 10 }}>Residential</span>}
                  defaultChecked
                  value={propertyToggle}
                  onChange={(e) => {
                    dispatch(setpropertyToggle(!propertyToggle));
                    dispatch(setSearchContext(!e ? 'residentialProperty' : 'commercialProperty'));
                    dispatch(reset_data());
                    setSearchQuery('');
                    setSearchParams('');
                    if (!pathname?.includes('/leadgen/search')) navigateTo(`/leadgen/search`);
                  }}
                />
              )}
              <div className={isAbove1920 ? 'topNavBarAbove1920' : ''}>
                {isAbove1920 && (
                  <div className="d-flex a-center">
                    <div style={{ width: '100%', borderRadius: '8px' }}>
                      <div>
                        {(propertyToggle ? commericalPropertyRadios : residentialPropertyRadios)?.map((element) => (
                          <span
                            onClick={() => {
                              if (searchContext == element?.value) {
                                return;
                              }
                              dispatch(reset_data());
                              setSearchQuery('');
                              setSearchParams('');
                              if (!pathname?.includes('/leadgen/search')) navigateTo(`/leadgen/search`);
                              if (element?.disabled) return;
                              dispatch(setSearchContext(element?.value));
                            }}
                            className={`${element?.disabled ? 'disabled' : ''} ${
                              searchContext == element?.value ? 'radioBtnSelected' : ''
                            } radioBtnCustom`}
                            key={nanoid()}
                          >
                            {searchContext === element?.value && <CheckSquareOutlined />}
                            {element?.label}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <Input
                  onChange={(e) => {
                    setSearchQuery(e?.target?.value);
                  }}
                  value={searchQuery}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      searchParam(e?.target?.value);
                    }
                  }}
                  placeholder="Show me properties in Hinjewadi"
                  className="topnav-placeholder"
                />
              </div>
              <Button
                disabled={searchQuery?.length === 0 || !searchQuery}
                type="primary"
                className="topnav-search__button"
                onClick={() => {
                  if (searchQueries === searchQuery) return;
                  dispatch(reset_data());
                  dispatch(setSelectedOrganizationId(null));
                  navigateTo(`/leadgen/search?searchQuery=${searchQuery}`);
                }}
              >
                Search
              </Button>
            </Space>
            {isAbove1320 && <PropertyTypeToggle setSearchParams={setSearchParams} setSearchQuery={setSearchQuery} />}
          </div>
        }
        <div className="header__actions">
          <Space size="large">
            <div
              className="actions-item"
              onClick={() => {
                navigateTo(`/user/myaccount?activeTab=transactional_details`);
              }}
            >
              Points <span className="point-item">{userPoints}</span>
            </div>

            <Space size="middle">
              <Popover
                content={<div>{notificationContent}</div>}
                title={<Text>Notification</Text>}
                trigger="click"
                open={open}
                onOpenChange={handleOpenChange}
                placement="bottomRight"
                style={{ zIndex: 'auto !important' }}
              >
                <Badge className="topnav__notification" size="default" count={getUnreadNotificationCount()}>
                  <BellOutlined style={{ fontSize: '30px' }} className="notification-item" />
                </Badge>
              </Popover>
            </Space>
            <Dropdown overlay={profileMenu} trigger={['hover']}>
              <div
                className="block-items"
                onClick={() => {
                  if (profileCompletion?.overallCompletion > 80) {
                    navigateTo('/user/myaccount');
                  }
                }}
              >
                <Avatar size="large" src={user?.personalDetails?.imageUrl} icon={<UserOutlined />} />
                <div className="items-desc">
                  <Progress
                    size="small"
                    type="circle"
                    percent={profileCompletion?.overallCompletion}
                    showInfo={false}
                    strokeColor="#0081FC"
                  />
                </div>
              </div>
            </Dropdown>
          </Space>
        </div>
      </div>
      {!isAbove1320 && !isAbove1920 && (
        <PropertyTypeToggle setSearchParams={setSearchParams} setSearchQuery={setSearchQuery} />
      )}
    </Header>
  );
};

export default TopNav;
