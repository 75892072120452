import { CloseCircleFilled, LineOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { truncate } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
const NewsCardAudioComponent = ({ postDetails }) => {
  const audioLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'audio')?.url;
  const imageLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'image')?.url;
  const [showVideoModal, setShowVideoModal] = useState(false);
  const playerRef = useRef();

  useEffect(() => {
    const internalPlayer = playerRef?.current ? playerRef.current.getInternalPlayer() : null;
    if (showVideoModal) {
      internalPlayer?.play();
      internalPlayer?.seekTo(0);
    } else {
      internalPlayer?.pause();
    }
    return () => internalPlayer?.pause();
  }, [showVideoModal]);

  return (
    <>
      <div className="news_card_post_audio_video">
        <img src={imageLink} />
        <span onClick={() => setShowVideoModal(true)} className="audioContentPlayIcon">
          <span className="title mb-20">{truncate(postDetails?.title, { length: 100 })}</span>
          <div className="d-flex a-center g-10">
            <PlayCircleOutlined />
            <span className="audioPlayerline"></span>
          </div>
        </span>
      </div>
      <Modal
        onCancel={() => {
          setShowVideoModal(false);
          playerRef.current.getInternalPlayer().pause();
        }}
        className="modalAudioVideo"
        wrapper={'audio'}
        maskClosable
        closeIcon={<CloseCircleFilled className="font20 fontWhite" />}
        width={700}
        footer={null}
        open={showVideoModal}
        centered
      >
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width={700}
          height={700}
          controls={false}
          playIcon={<PlayCircleOutlined />}
          playing={showVideoModal}
          url={audioLink}
        />
      </Modal>
    </>
  );
};

export default NewsCardAudioComponent;
