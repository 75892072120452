import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import NewsCardAudioComponent from './NewsCardAudioComponent';
import NewsCardImageComponent from './NewsCardImageComponent';
import './newsCardPost.scss';
import NewsCardVideoComponent from './NewsCardVideoComponent';
import { Divider } from 'antd';
import { truncate } from 'lodash';

const NewsCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
}) => {
  const sanitizer = DOMPurify.sanitize;
  const AudioVideoComponetMap = {
    audio: NewsCardAudioComponent,
    video: NewsCardVideoComponent,
    image: NewsCardImageComponent,
  };
  const AudioVideoComponet = AudioVideoComponetMap?.[postDetails?.postDetails?.subType?.toLowerCase()];

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  return (
    <div className="news_card_post_content ">
      {AudioVideoComponet && <AudioVideoComponet postDetails={postDetails} />}
      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={postDetails} />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <div className="publisherContainer">
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.publisherName) }}
            ></div>
            <Divider type="vertical" className="divider" />
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(formatDate(postDetails?.postDetails?.date)) }}
            ></div>
            <Divider type="vertical" className="divider" />
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.place) }}
            ></div>
          </div>
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          {postDetails?.postDetails?.subType?.toLowerCase() === 'image' ? (
            <div className="d-flex g-5">
              <div
                className={`font14 w-50 postBody fontExtraLight ${
                  postDetails?.postDetails?.subType?.toLowerCase() === 'image' ? ' firstLetter' : ''
                }`}
                dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body?.slice(0, 200)) }}
              ></div>
              <div
                className={`font14 w-50 postBody fontExtraLight `}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(truncate(postDetails?.postDetails?.body?.slice(200), { length: 200 })),
                }}
              ></div>
            </div>
          ) : (
            <div
              className={`font14 postBody fontExtraLight ${
                postDetails?.postDetails?.subType?.toLowerCase() === 'image' ? ' firstLetter' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
            ></div>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default NewsCardPost;
