import { Menu, Spin } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Link } from 'react-router-dom';

import MyAccountIcon from '../../../home/images/MyAccount';

import LeadGenSideIcon from '../../../home/images/LeadGenIcon';
import SocialAppIcon from '../../../home/images/SocialIcon';
import MyLeadsIcon from '../../../home/images/MyLeadsIcon';
import SaveForLaterIcon from '../../../home/images/SaveForLaterIcon';
import SubscriptionsIcon from '../../../home/images/SubscriptionsIcon';

import './sideNav.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedNav } from '../../../../leadgen/features/universalSidenavSlice';
import { fetchUserOnboardingData } from '../../../../universal/features/userOnboardingSlice';
function getMenuItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const SideNav = () => {
  const dispatch = useDispatch();

  const { selectedNav } = useSelector((store) => store.universalSideNav);
  const { profileCompletion } = useSelector((store) => store.user);

  const [loading, setLoading] = useState(false);
  const [navItems, setNavItems] = useState([]);

  const items = [
    getMenuItem(
      <Link
        to="/user/socialApp"
        onClick={() => {
          dispatch(setSelectedNav('socialApp'));
        }}
      >
        <span style={{ color: selectedNav === 'socialApp' ? '#FFA300' : '#FFF' }}>Social</span>
      </Link>,
      'socialApp',
      <SocialAppIcon strokeColor={selectedNav === 'socialApp' ? '#FFA300' : '#FFF'} />,
    ),
    getMenuItem(
      <Link
        to="/leadgen/search"
        onClick={() => {
          dispatch(setSelectedNav('LeadGen'));
        }}
      >
        <span style={{ color: selectedNav === 'LeadGen' ? '#FFA300' : '#FFF' }}>Lead Gen</span>
      </Link>,
      'LeadGen',
      <LeadGenSideIcon strokeColor={selectedNav === 'LeadGen' ? '#FFA300' : '#FFF'} />,
    ),

    getMenuItem(
      <Link
        to="/leadgen/contactbook"
        onClick={() => {
          dispatch(setSelectedNav('MyLeads'));
        }}
      >
        <span style={{ color: selectedNav === 'MyLeads' ? '#FFA300' : '#FFF' }}>My Leads</span>
      </Link>,
      'MyLeads',
      <MyLeadsIcon strokeColor={selectedNav === 'MyLeads' ? '#FFA300' : '#FFF'} />,
    ),

    getMenuItem(
      <Link
        to="/user/myaccount"
        onClick={() => {
          dispatch(setSelectedNav('MyAccount'));
        }}
      >
        <span style={{ color: selectedNav === 'MyAccount' ? '#FFA300' : '#FFF' }}>My Account</span>
      </Link>,
      'MyAccount',
      <MyAccountIcon strokeColor={selectedNav === 'MyAccount' ? '#FFA300' : '#FFF'} />,
    ),
    getMenuItem(
      <Link
        to="/leadgen/saved"
        onClick={() => {
          dispatch(setSelectedNav('SavedForLater'));
        }}
      >
        <span
          style={{
            color: selectedNav === 'SavedForLater' ? '#FFA300' : '#FFF',
            marginLeft: 10,
            marginRight: 10,
            whiteSpace: 'nowrap',
            width: 60,
            height: 17,
            textAlign: 'match-parent',
          }}
          className="sideBarText"
        >
          Saved For Later
        </span>
      </Link>,
      'SavedForLater',
      <SaveForLaterIcon strokeColor={selectedNav === 'SavedForLater' ? '#FFA300' : '#FFF'} />,
    ),
    getMenuItem(
      <Link
        to="#"
        onClick={() => {
          dispatch(setSelectedNav('Subscriptions'));
        }}
      >
        <span style={{ color: selectedNav === 'Subscriptions' ? '#FFA300' : '#FFF' }}>Subscriptions</span>
      </Link>,
      'Subscriptions',
      <SubscriptionsIcon strokeColor={selectedNav === 'Subscriptions' ? '#FFA300' : '#FFF'} />,
    ),
  ];
  useEffect(() => {
    dispatch(fetchUserOnboardingData());
    dispatch(setSelectedNav('socialApp'));
  }, []);
  useEffect(() => {
    if (profileCompletion?.overallCompletion < 80) {
      const filteredNavItems = items?.filter(
        (el) =>
          el?.key != 'socialApp' &&
          el?.key != 'Subscriptions' &&
          el?.key != 'SavedForLater' &&
          el?.key != 'MyLeads' &&
          el?.key != 'LeadGen' &&
          el?.key != 'MyAccount',
      );
      setNavItems(filteredNavItems);
      return;
    }
    setNavItems(items);
  }, [profileCompletion?.overallCompletion]);
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Sider width={100} className="universalSidebar">
        <Menu
          theme="dark"
          // mode="inline"
          mode="vertical"
          selectedKeys={selectedNav}
          defaultSelectedKeys={[]}
          items={navItems}
          expandIcon={null}
        />
      </Sider>
    </>
  );
};

export default SideNav;
