// Css Imports
import './viewUserProfile.scss';

// React,React-Redux,Router Imports
import React from 'react';

// Design Library Imports
import { Divider } from 'antd';
import ViewProfileContent from '../../../components/socialApp/viewProfile/ViewProfileContent';
import ViewProfileHeader from '../../../components/socialApp/viewProfile/ViewProfileHeader';

// Slices and reducer imports

// Component Imports

const ViewUserProfile = () => {
  return (
    <div className="viewProfileContainer">
      <div className="viewProfileContainer__profileContainer">
        <ViewProfileHeader />
        <Divider style={{ margin: 0, background: '#949494' }} />
        <ViewProfileContent />
      </div>
      <div className="viewProfileContainer__middleContainer"></div>
    </div>
  );
};

export default ViewUserProfile;
