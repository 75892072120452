import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Divider, Form, Input,Typography } from 'antd';
import BackIcon from '../../../layout/default/BackIcon';
import LinkedinIcon from '../../../assets/images/linkedin.svg';

//  styles
import './register.scss';

const RegisterForm = ({ handleSubmit, loading, onCode, onClose, OauthPopup, url }) => {
  const [checkedStatus, setCheckedStatus] = useState(false);
  const navigateTo = useNavigate();
  const { Text } = Typography;

  return (
    <div className="registrationcard-box">
      <div className="registrationcard-header">
        <BackIcon onClick={() => navigateTo('/')} className='backicon' />
        <h2 className="header-text">Registration</h2>
      </div>
      <div>
        <Form
          className="registrationcard-box__form"
          name="basic"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Name"
             className="form-item"
            rules={[
              {
                required: true,
                message: 'Please input valid name.',
                pattern: /^[a-zA-Z\s]+$/,
              },
            ]}
          >
            <Input placeholder="Enter Name" />
          </Form.Item>

          <Form.Item
            name="email"
            label="Work E-mail"
             className="form-item"
            rules={[
              {
                message: 'Please input valid email Id',
                type: 'email',
              },
              {
                required: true,
                message: 'Please input your email Id',
              },
            ]}
          >
            <Input placeholder="Enter Company's E-mail" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Mobile No."
             className="form-item"
            rules={[
              {
                message: 'Please input valid mobile no.',
                pattern: /^[0-9]{10}$/,
              },
              {
                required: true,
                message: 'Please input your mobile no.',
              },
            ]}
          >
            <Input type="number" placeholder="Enter Mobile No." className='aadhar-input' />
          </Form.Item>

          <Form.Item
            name="termsAgree"
            valuePropName="checked"
            className="form-item"
            rules={[
              {
                validator: (_, value) =>
                  value ? Promise.resolve() : Promise.reject(new Error('Please accept Terms & Conditions')),
              },
            ]}
          >
            <Checkbox onChange={() => setCheckedStatus(!checkedStatus)}>I agree to Terms & Conditions</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading.registerLoading}
              disabled={!checkedStatus}
              type="primary"
              style={{
                background: checkedStatus ? '#023FAC' : '',
                fontSize: '12px',
              }}
              block
              htmlType="submit"
            >
              Submit
            </Button>
            <Text className="login-msg">
            Already registered, Go to 
            <Link to="/" className="register-to__login">
              &nbsp;Sign In 
            </Link>
            &nbsp;page
          </Text>
          </Form.Item>

          <Divider className="form-divider">or sign in with</Divider>

          <div className="form-item">
            <OauthPopup url={url} onCode={onCode} onClose={onClose}>
              <Button className="form-button" type="primary">
                <img className="form-img" src={LinkedinIcon} alt="linkedIn" />
                Login via Linkedin
              </Button>
            </OauthPopup>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterForm;
