import { FileOutlined, LeftOutlined, LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { nanoid } from '@reduxjs/toolkit';
import {
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Tag,
  TimePicker,
  Typography,
  Upload,
  message,
} from 'antd';
import React, { useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import { emailRegex } from '../../../../../../utils/Constants';
import { keySkills } from '../../../../../../utils/referenceData/personalDetailsOfferings';
import { socialAppCreatePost, uploadPostImage } from '../../../../../features/socialAppSlice';
import CommonButton from '../../../common/CommonButton';
import SelectUploadedImages from '../SelectUploadedImages';
import ReactQuillComponent from '../common/ReactQuillComponent';
import MultipleSelectTags from './MultipleSelectTags';
import './jobPostForm.scss';
const JobPostForm = ({
  setShowForm = () => {},
  setAllposts,
  closePopup = () => {},
  setActiveTab = () => {},
  isCompany,
  postRel = 'user_post',
  isPostFromMyAccount = false,
}) => {
  const dispatch = useDispatch();
  const { Text } = Typography;

  // form derails
  const [formDetails] = Form.useForm();

  // states

  const [loader, setLoader] = useState(false);
  const [imgLoader, setImageloader] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [imgUrls, setImgUrls] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [keySkillsList, setKeySkillsList] = useState([]);
  const options = keySkills.map((el) => {
    return {
      label: el,
      value: el,
    };
  });
  const selectProps = {
    value: keySkillsList,
  };

  const userId = useSelector((state) => state.user?.user?.id);
  const userv2 = useSelector((state) => state.user?.userV2);
  const companyId = useSelector((state) => state.user?.userV2?.companyDetails?.companyId);

  // handle image upload
  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append('files', file);
    setImageloader(true);
    dispatch(uploadPostImage(formData))?.then((res) => {
      setImageloader(false);
      message.success(`${file?.name} uploaded successfully...`);
      if (res?.payload?.data?.response?.data) {
        setImgNames((prev) => [...(prev || []), file?.name]);
        setImgUrls((prev) => [...(prev || []), ...(res?.payload?.data?.response?.data || [])]);
      }
    });
  };

  // handle submit
  const handleFormSubmit = (values) => {
    const createPostPayload = {
      type: 'job_card',
      title: values?.job_title || '',
      imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
      postDetails: {
        isPostingForSomeone: values?.isPostingForSomeone ? true : false,
        company: values?.company_name,
        email: values?.reciever_email,
        body: values?.job_description,
        title: values?.job_title,
        location: values?.job_location,
        dateTime: {
          date: values?.date,
          time: values?.time,
        },
        industry: values?.industry,
        skills: values?.keySkills,
        workplaceType: values?.workplace_type,
        jobType: values?.job_type,
      },
      ownerType: postRel,
      ownerId: postRel === 'company_post' ? companyId : userId,
    };
    setLoader(true);
    dispatch(socialAppCreatePost(createPostPayload))?.then((res) => {
      setLoader(false);
      if (res?.payload?.data?.response) {
        closePopup();
        setActiveTab(isCompany ? 'COMPANY_POST' : 'MY_POST');
        setShowForm(false);
        message.success('Post created successfully...');
        if (!isPostFromMyAccount) {
          const addedPost = {
            _id: res?.payload?.data?.response?.data?._id,
            type: 'job_card',
            title: values?.job_title || '',
            ownerId: userId,
            ownerType: 'user_post',
            likes: 0,
            comments: 0,
            imageUrls: imgUrls?.map((elem) => ({ url: elem, type: 'image' })),
            isOwner: true,
            postDetails: {
              isPostingForSomeone: values?.isPostingForSomeone,
              company: values?.company_name,
              email: values?.reciever_email,
              body: values?.job_description,
              title: values?.job_title,
              dateTime: {
                date: values?.date,
                time: values?.time,
              },
              location: values?.job_location,
              industry: values?.industry,
              skills: values?.keySkills,
              workplaceType: values?.workplace_type,
              jobType: values?.job_type,
            },
            ownerDetails: {
              _id: userId,
              name: userv2?.name,
              email: userv2?.email,
              professionalDetails: userv2?.personalDetails,
            },
          };
          setAllposts((prev) => ({
            ...prev,
            posts: [addedPost, ...(prev?.posts || [])],
            totalRecords: prev?.totalRecords + 1,
          }));
        }
      } else {
        message.error('Some error occurred...');
      }
    });
  };
  //JSX
  return (
    <div className="genericpostform">
      {!isPostFromMyAccount && (
        <div className="d-flex a-center jc-between g-10">
          <div className="d-flex a-center">
            <LeftOutlined
              style={{ fontSize: 20, marginRight: 10, cursor: 'pointer', color: '#0081FC' }}
              onClick={() => setShowForm(false)}
            />
            <Text className="font18 fontDark">Job Card</Text>
          </div>

          <div>
            <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
              Reset
            </CommonButton>
            <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
              Create Post
            </CommonButton>
          </div>
        </div>
      )}
      <Form layout="vertical" form={formDetails} onFinish={handleFormSubmit}>
        <div className="mt-10">
          <div className={`d-flex ${isPostFromMyAccount && 'd-column'} `}>
            <div className={`imageContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please upload a file!',
                  },
                ]}
                required
                name="imageURL"
                className="dropdown-form-item"
              >
                <div className="d-flex g-20" style={{ marginBottom: '20px' }}>
                  <Upload
                    listType="picture-card"
                    className="uploadImage"
                    multiple
                    accept=".png,.jpeg,.jpg"
                    customRequest={({ file }) => handleUpload(file)}
                    showUploadList={false}
                  >
                    {imgLoader ? (
                      <Spin indicator={<LoadingOutlined />} />
                    ) : (
                      <>
                        <UploadOutlined style={{ fontSize: 28, paddingBottom: '1rem' }} /> <Text>Upload Image</Text>
                      </>
                    )}
                  </Upload>
                </div>
                {/* ----Uploadeed images------ */}
                {imgNames?.length > 0 && (
                  <div style={{ maxHeight: '125px', overflow: 'scroll' }}>
                    {imgNames?.map((elem, index) => (
                      <div className="fontBlue" key={index}>
                        <FileOutlined /> {elem}
                      </div>
                    ))}
                  </div>
                )}
                {/* ----Uploadeed images------ */}
              </Form.Item>
              <Form.Item required label="" className="dropdown-form-item">
                <Form.Item name="imageURL">
                  <SelectUploadedImages imageUrl={imgUrls} setImgUrl={setImgUrls} setImageNames={setImgNames} />
                </Form.Item>
              </Form.Item>

              <Form.Item required className="dropdown-form-item" label="Workplace Type">
                <Space>
                  <Form.Item
                    name="workplace_type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Workplace type',
                      },
                    ]}
                  >
                    <MultipleSelectTags tagsData={['All', 'Onsite', 'Hybrid', 'Remote']} />
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item required className="dropdown-form-item" label="Job Type">
                <Space>
                  <Form.Item
                    name="job_type"
                    rules={[
                      {
                        required: true,
                        message: 'Select Job type',
                      },
                    ]}
                  >
                    <MultipleSelectTags tagsData={['Fulltime', 'Part Time', 'Contract', 'Other']} />
                  </Form.Item>
                </Space>
              </Form.Item>
            </div>
            <div className={`formContainer ${isPostFromMyAccount && 'w-100'}`}>
              <Form.Item
                name="posting_for_someone"
                required
                // label="Posting"
                className="isPostingForSomeone"
              >
                <Checkbox /> {'  '}Posting for someone else ?
              </Form.Item>
              <Form.Item
                name="company_name"
                required
                label="Company"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: '"Enter Company !',
                  },
                ]}
              >
                <Input placeholder="Enter Company" min={0} />
              </Form.Item>
              <Form.Item
                name="reciever_email"
                required
                label="Reciever Email"
                className="dropdown-form-item"
                rules={[
                  {
                    pattern: emailRegex,
                    message: 'Enter valid pincode',
                  },

                  {
                    required: true,
                    message: 'Enter Reciever Email!',
                  },
                ]}
              >
                {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                {/* <ReactQuillComponent /> */}
                <Input placeholder="Enter Reciever Email" min={0} />
                {/* <TextArea placeholder="Write Description here" style={{ width: '470px', height: 80 }} min={0} /> */}
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="job_location"
                    label="Job Location"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Job Location!',
                      },
                    ]}
                    className="buyerrow-form__space--text"
                    style={{ marginLeft: '17px' }}
                  >
                    <Input placeholder="Enter Job Location" style={{ width: '90%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="job_title"
                    label="Job Title"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Job Title!',
                      },
                    ]}
                    className="buyerrow-form__space--text"
                  >
                    <Input placeholder="Enter Job Title" style={{ width: '90%' }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="date"
                    label="Date"
                    rules={[
                      {
                        required: true,
                        message: 'Select Date!',
                      },
                    ]}
                    className="buyerrow-form__space--text"
                    style={{ marginLeft: '17px', width: '100%' }}
                  >
                    <DatePicker placeholder="DD/MM/YYYY" style={{ width: '90%' }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="time"
                    label="Time"
                    rules={[
                      {
                        required: true,
                        message: 'Select Time!',
                      },
                    ]}
                    className="buyerrow-form__space--text"
                  >
                    <TimePicker use12Hours format="h:mm a" style={{ width: '90%' }} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="job_description"
                required
                label="Job Description"
                className="dropdown-form-item"
                rules={[
                  {
                    required: true,
                    message: 'Enter Job Description!',
                  },
                ]}
              >
                <ReactQuillComponent placeholder="Write Description Here" />
              </Form.Item>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="industry"
                    className="dropdown-form-item"
                    label="Industry/Domain"
                    rules={[
                      {
                        required: true,
                        message: 'Enter Industry!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter Industry" style={{ width: '90%' }} min={0} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Row>
                    <Space
                      className="keySkillsSpace"
                      style={{
                        width: '100%',
                      }}
                    >
                      <Form.Item
                        rules={[
                          {
                            required: 'true',
                            message: 'Select Skills',
                          },
                        ]}
                        className="dropdown-form-item"
                        name="keySkills"
                        label="Key Skills"
                      >
                        <Select
                          className="keySkills"
                          mode="multiple"
                          style={{
                            width: '100%',
                            height: '40px',
                          }}
                          {...selectProps}
                          placeholder="Select Skills"
                          options={options}
                          onChange={setKeySkillsList}
                        />
                      </Form.Item>
                    </Space>
                    <div style={{ maxHeight: '100px', overflow: 'scroll' }}>
                      {keySkillsList?.map((el) => {
                        return (
                          <Tag
                            key={nanoid()}
                            color="#3F52A3"
                            style={{
                              borderRadius: '100px',
                              marginBottom: '15px',
                              paddingLeft: '20px',
                              paddingRight: '20px',
                            }}
                          >
                            <span style={{ color: 'white', fontSize: '12px' }}>{el}</span>
                          </Tag>
                        );
                      })}
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {isPostFromMyAccount && (
          <div className="d-flex a-center jc-end g-10">
            <div>
              <CommonButton ghost size="large" onClick={() => setShowForm(false)} style={{ marginRight: 10 }}>
                Reset
              </CommonButton>
              <CommonButton loader={loader} type="primary" htmlType="submit" onClick={() => formDetails.submit()}>
                Create Post
              </CommonButton>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
};

export default JobPostForm;
