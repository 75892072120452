import { RightOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { useState } from 'react';
import './ResidentialPropertyDetailsPage.scss';
import SimilarCompaniesSkeleton from '../propertyDetailsPage/components/CompanyDetails/skeletons/SimilarCompaniesSkeleton';
import SelectedPropertyUnitCard from './SelectedPropertyUnitCard';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getOccupantDetailsFullInfo } from '../../features/searchSlice';
import animationData from '../../../assets/images/SelectedLottie.json';
import LottieAnimation from '../../../universal/components/lottieAnimation/LottieAnimation';
const SelectedUnits = ({ propertyData = [], loader, selectedPropertyData, emptyDataImage }) => {
  const dispatch = useDispatch();
  const { occupantDetails } = useSelector((state) => state.search);

  const [filteredUnit, setFilteredUnit] = useState(occupantDetails ? occupantDetails._id : null);

  const handleUnitClick = (unitId) => {
    if (filteredUnit === unitId) {
      setFilteredUnit(null);
    } else {
      setFilteredUnit(unitId);
    }
  };

  const onUnitClick = (id) => {
    dispatch(getOccupantDetailsFullInfo(id));
  };

  return (
    <div style={{ gap: '10px', display: 'flex', flexDirection: 'column' }}>
      <SelectedPropertyUnitCard propertyData={occupantDetails} />
      <div style={{ overflow: 'scroll' }} className="unitContainer">
        <div className="unit-title-container font900 font14 fontLight-3">Unit in {selectedPropertyData.buildingName}</div>
        <div className="similarPropertyScrollbar jc-center pr-10" style={{ maxHeight: '500px', overflow: 'scroll' }}>
          {propertyData.length === 0 ? (
            <div className="unit-imgdiv">
              <LottieAnimation height={150} width={150} animationData={animationData} loop={true} />
              <span className="bold unit-imgdiv__text">
                We are finding right<br></br> <span className="bold text-middleunit">UNIT</span>
                <br></br>
                For you, Be with us !
              </span>
            </div>
          ) : (
            propertyData.map((ele, index) =>
              loader ? (
                <SimilarCompaniesSkeleton key={index} />
              ) : (
                (filteredUnit === null || filteredUnit !== ele?._id) && (
                  <div
                    key={index}
                    onClick={() => {
                      onUnitClick(ele?._id);
                      handleUnitClick(ele?._id);
                    }}
                    className="d-flex a-center jc-between mt-15 p-10 fontExtraLight bold similarPropertiesHover"
                  >
                    <span className="d-flex g-10 jc-between">
                      <Avatar src={ele?.assetsInfo?.[0]?.featuredImage} />
                      <span className="d-flex" style={{ gap: '100px' }}>
                        <div className="d-flex d-column jc-between" style={{ width: '120px' }}>
                          <span className="font10  cursor-pointer">
                            Flat No. {ele?.address?.unitNo ? `${ele?.address?.unitNo}` : 'Awaited'}
                          </span>
                          <span className="font10  cursor-pointer">
                            Floor No. {ele?.address?.floorNo ? `${ele?.address?.floorNo}` : 'Awaited'}
                          </span>
                        </div>
                        <span className="font10 cursor-pointer registration-date">
                          Registration Date:{' '}
                          {ele?.registrationDate ? `${dayjs(ele?.registrationDate).format('DD MMM. YYYY')}` : 'Awaited'}
                        </span>
                        <span className="unit-property-doc-type">
                          {ele?.documentType ? ele?.documentType : 'Awaited'}
                        </span>
                      </span>
                    </span>
                    <RightOutlined className="fontLight-2" />
                  </div>
                )
              ),
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectedUnits;
