import React from 'react';
import ReactPlayer from 'react-player/lazy';
const NewsCardVideoComponentMyAccount = ({ postDetails }) => {
  const videoLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'video')?.url;
  const imageLink = postDetails?.imageUrls?.find((elem) => elem?.type == 'image')?.url;
  // light={<img src={imageLink} alt="Thumbnail" />}
  return (
    <div className="imgcontainer position-relative">
      <ReactPlayer
        className="react-player"
        light={<img src={imageLink} alt="Thumbnail" />}
        width={204}
        height={204}
        playing
        url={videoLink}
      />
    </div>
  );
};

export default NewsCardVideoComponentMyAccount;
