import React from 'react';
import Text from 'antd/es/typography/Text';
import CommonButton from '../../../common/CommonButton';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';
import DOMPurify from 'dompurify';

import './genericPost.scss';
import PostHeader from '../../postHeader/PostHeader';
import PostFooter from '../../postFooter/PostFooter';

const GenericPost = ({
  isViewPost,
  postDetails,
  setPostDetails,
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  return (
    <div className="generic_post_content ">
      <div className="generic_post_images">
        {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
          <ImageCarousel className="carousel-image" imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []} />
        ) : (
          <img src={defaultImage} alt="Default Post" className="carousel-image" />
        )}
      </div>
      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={postDetails} />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>
          <span style={{ display: 'flex', flexWrap: 'wrap' }} className="d-flex font500 fontExtraLight g-5">
            {postDetails?.postDetails?.tags?.map((elem, index) => (
              <span style={{ flex: 'none' }} key={index} className=" font14 font500 fontExtraLight g-15">
                #{elem}
              </span>
            ))}
          </span>

          {postDetails?.postDetails?.CTA && (
            <CommonButton style={{ width: '90px' }} target="_blank" href={postDetails?.postDetails?.CTA?.link}>
              {postDetails?.postDetails?.CTA?.name}
            </CommonButton>
          )}
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default GenericPost;
