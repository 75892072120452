import { DownloadOutlined } from '@ant-design/icons';
import Text from 'antd/es/typography/Text';
import DOMPurify from 'dompurify';
import React from 'react';
import CommonButton from '../../../common/CommonButton';
import PostFooter from '../../postFooter/PostFooter';
import PostHeader from '../../postHeader/PostHeader';
import './eventCardPost.scss';
import { Divider } from 'antd';
import ImageCarousel from '../common/imageCarousel/ImageCarousel';
import EventCardImageCarousel from './EventCardImageCarousel';

const EventCardPost = ({
  isViewPost,
  postDetails,
  setPostDetails = () => {},
  setAllposts = () => {},
  handleFollow = () => {},
  handleUnFollow = () => {},
  isUserPost,
  setPostData = () => {},
}) => {
  const defaultImage = 'https://placehold.jp/18/1677ff/ffffff/200x200.png?text=TryThat.ai';
  const sanitizer = DOMPurify.sanitize;

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    });
  };

  return (
    <div className="report_card_post_content ">
      <div className="generic_post_images">
        {postDetails?.imageUrls && postDetails.imageUrls?.length > 0 ? (
          <EventCardImageCarousel
            className="carousel-image"
            imageUrls={postDetails.imageUrls?.map((elem) => elem?.url) || []}
            postDetails={postDetails}
          />
        ) : (
          <img src={defaultImage} alt="Default Post" className="carousel-image" />
        )}
      </div>

      <div className="d-flex d-column jc-between w-100 ">
        <PostHeader handleFollow={handleFollow} handleUnFollow={handleUnFollow} postDetails={postDetails} />
        <div className="d-flex g-5 d-column pt-20 flex-2">
          <Text className="font14 fontDark font700">{postDetails?.title}</Text>
          <div className="publisherContainer">
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.organiser) }}
            ></div>
            <Divider type="vertical" className="divider" />
            <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(formatDate(postDetails?.postDetails?.dateTime?.date)) }}
            ></div>
            <Divider type="vertical" className="divider" />
            {/* <div
              className="font14 postBody fontExtraLight"
              dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.place) }}
            ></div> */}
          </div>

          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.synopsys) }}
          ></div>
          <div
            className="font14 postBody fontExtraLight"
            dangerouslySetInnerHTML={{ __html: sanitizer(postDetails?.postDetails?.body) }}
          ></div>
        </div>
        <PostFooter
          isViewPost={isViewPost}
          setAllPosts={setAllposts}
          setPostDetails={setPostDetails}
          handleFollow={handleFollow}
          postDetails={postDetails}
          allPostsPage={true}
          isUserPost={isUserPost}
          setPostData={setPostData}
        />
      </div>
    </div>
  );
};

export default EventCardPost;
