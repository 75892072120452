import { Card, Col, Modal, Space, Button, Avatar } from 'antd';
import ProspectImage from '../../components/prospectImage/ProspectImage';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';
import PhotoGallery from '../../components/photoGallery/PhotoGallery';
import Reviews from '../../components/reviews/Reviews';
import DataGrid from '../../components/dataGrid/DataGrid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDetailedPropertyInfo,
  getOccupantsFromProperty,
  getVacantUnitsFromProperty,
  addBreadcrumb,
  setNavigateFrom,
  unlockVacantUnitFields,
  truncateBreadcrumbs,
} from '../../features/searchSlice';
import { setInsufficientPoints, setTransactionModal } from '../../features/savedSlice';
import { isEmpty } from 'lodash';
import { FormatOccupantsData } from '../../../utils/formatSearchData/Occupants';
import { FormatVacantUnitsData } from '../../../utils/formatSearchData/VacantUnit';
import AmenitiesIconEnum from '../../../utils/referenceData/search/amenitiesIconEnum';
import { getPropertyReviews } from '../../features/contactBookSlice';
import properties from '../../../assets/images/WALL.svg';
import search_organization from '../../../assets/images/search_organization.svg';

import './CommercialPropertyDetailsCard.scss';
import Address from '../../components/address/Address';
import { UserOutlined } from '@ant-design/icons';
import { populateAddressInfo } from '../../../utils/formatSearchData/PropertyAddress';
import LockedIcon from '../../../layout/home/images/LockedIcon';
import UnlockedIcon from '../../../layout/home/images/UnlockedIcon';

const CommercialPropertyDetailsCard = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { detailedPropertyData, occupantsData, vacantUnitsData, breadcrumbs } = useSelector((store) => store.search);
  const { transactionModal, insufficientPoints } = useSelector((store) => store.saved);
  const { propertyReviews } = useSelector((store) => store.contactBook);
  const { selectedNav } = useSelector((store) => store.leadGenSideNav);

  const [propertyDetails, setPropertyDetails] = useState({});
  const [occupantsDetails, setOccupantsDetails] = useState([]);
  const [vacantUnitDetails, setVacantUnitDetails] = useState([]);
  const [reviwesDetails, setReviewsDetails] = useState([]);
  const [activeTab, setActiveTab] = useState('occupants');

  const occupantsListColumns = [
    {
      title: 'Floor No.',
      dataIndex: 'floorNo',
      key: 'floorno',
    },
    {
      title: 'Unit No.',
      dataIndex: 'unitNo',
      key: 'unitno',
    },
    {
      title: 'Occupant',
      dataIndex: 'occupantName',
      key: 'occupantname',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.isPerson ? (
              <UserOutlined />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}

            <Text style={{ fontSize: '12px', maxWidth: '120px' }} ellipsis={{ tooltip: text }}>
              {text}
            </Text>
          </div>
        );
      },
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text.id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  const vacantUnitListColumns = [
    {
      title: '',
      dataIndex: 'vacantProfileImage',
      key: 'vacantProfileImage',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            {record?.vacantProfileImage ? (
              <Avatar src={record?.vacantProfileImage} />
            ) : (
              <img
                src={search_organization}
                alt="location"
                className="organizationListContainer__div__card__row2__col1__row__col__img"
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Chargable Area',
      dataIndex: 'chargableArea',
      key: 'chargableArea',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.chargableArea).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      render: (text, record) => {
        return <span>{`${parseFloat(record?.rate).toFixed(2)} Sq.Ft.`}</span>;
      },
    },
    {
      title: 'Transaction Type',
      dataIndex: 'transactionType',
      key: 'transactionType',
      render: (text, record) => {
        return (
          <span className="unit-property-doc-type">
            {record?.transactionType ? record?.transactionType : 'Awaited'}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'isResourceLocked',
      key: 'isResourceLocked',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record?.isResourceLocked ? <LockedIcon /> : <UnlockedIcon />}</div>;
      },
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <span
          style={{ cursor: 'pointer', fontSize: '15px' }}
          onClick={() => {
            dispatch(setNavigateFrom('PropertyDetails'));
            navigate(`/leadGen/occupantDetails/${text._id}`);
          }}
        >
          {'>'}
        </span>
      ),
    },
  ];

  const onRowClick = (id, page) => {
    if (page === 'occupants') {
      navigate(`/leadGen/occupantDetails/${id}`);
    } else if (page === 'vacantUnits') {
      const vacantUnitData = vacantUnitDetails?.filter((item) => item?.id === id);
      if (vacantUnitData?.[0]?.isResourceLocked == false) {
        navigate(`/leadGen/unitDetails/${id}`);
        return;
      }
      const transactPayload = {
        body: {
          payload: [
            {
              resourceType: 'commercial',
              resourceSubType: 'vacantUnit',
              resourceId: id,
              unlockedFields: ['address', 'poc'],
            },
          ],
        },
      };
      dispatch(unlockVacantUnitFields(transactPayload))
        .then((res) => {
          if (res?.error?.message === 'Rejected') {
            dispatch(setTransactionModal(true));
            dispatch(setInsufficientPoints(100));
            return;
          }
          navigate(`/leadGen/unitDetails/${id}`);
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }
    dispatch(setNavigateFrom('PropertyDetails'));
  };

  useEffect(() => {
    dispatch(getDetailedPropertyInfo(id));
    dispatch(getOccupantsFromProperty(id));
    dispatch(getPropertyReviews(id));
    dispatch(getVacantUnitsFromProperty(id));
    if (detailedPropertyData?.buildingName) {
      dispatch(
        addBreadcrumb({ name: `${detailedPropertyData?.buildingName}`, path: `/leadGen/propertyDetails/${id}` }),
      );
    }
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size > 0) {
      const activePage = queryParams.get('list');
      setActiveTab(activePage);
    }
  }, [id]);

  useEffect(() => {
    if (isEmpty(detailedPropertyData)) return;
    setPropertyDetails(detailedPropertyData);
  }, [detailedPropertyData]);

  useEffect(() => {
    if (isEmpty(occupantsData)) return;
    const data = FormatOccupantsData(occupantsData);
    setOccupantsDetails(data);
  }, [occupantsData]);

  useEffect(() => {
    if (isEmpty(vacantUnitsData)) return;
    const data = FormatVacantUnitsData(vacantUnitsData);
    setVacantUnitDetails(data);
  }, []);

  useEffect(() => {
    if (isEmpty(propertyReviews)) return;
    setReviewsDetails(propertyReviews);
  }, [propertyReviews]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section className="personalDtlsSection">
      <Card className="personalDtlsSection-card">
        <Space direction="vertical">
          <Title level={4} strong className="card__title">
            <span
              onClick={() => {
                if (selectedNav !== 'ContactBook') {
                  dispatch(truncateBreadcrumbs(breadcrumbs?.[breadcrumbs.length - 2]?.name));
                  navigate(breadcrumbs?.[breadcrumbs.length - 2]?.path);
                  return;
                }
                navigate('/leadGen/contactbook');
              }}
              className="card__title-pointer"
            >
              {'< '}
              <span style={{ fontSize: '16px', verticalAlign: 'middle', fontWeight: '600' }}>
                {propertyDetails?.buildingName}
              </span>
            </span>
          </Title>
          <div className="prospectImage">
            <ProspectImage imageUrl={propertyDetails?.featuredImage || properties} />
          </div>
          <Text style={{ fontWeight: '900' }}>Building Mgmt. Contact Details</Text>
          <Text style={{ fontSize: '12px' }}>Name : {propertyDetails?.ownerName}</Text>
          <Text style={{ fontSize: '12px' }}>Mobile No : {propertyDetails?.phone}</Text>
          <Text style={{ fontSize: '12px' }}>Email : {propertyDetails?.email}</Text>
          <Text style={{ fontSize: '12px' }}>Developer Name : {propertyDetails?.developer}</Text>
          <br />
          <Address addressInfo={populateAddressInfo(propertyDetails?.addressInfo)} />
          <br />
          <Text style={{ fontWeight: '900' }}>About</Text>
          <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Text style={{ fontSize: '12px' }}>{propertyDetails?.about}</Text>
          </div>
        </Space>
      </Card>
      <Card style={{ flex: 1.2 }} className="personalDtlsSection-card">
        <Text className="font18 font600">Key Information</Text>
        <Space direction="vertical" className="w-100">
          <Space
            direction="vertical"
            className="w-100 p-10 mt-10"
            style={{ border: '2px solid #ffa31d', borderRadius: '10px' }}
          >
            <Text className="font12">Building Type : {propertyDetails?.buildingType}</Text>
            <Text className="font12">Building Status : {propertyDetails?.buildingStatus}</Text>
            <Text className="font12">
              Average sale rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageSaleRate).toFixed(2)} Rs/sqft`
                : 'Awaited'}
            </Text>
            <Text className="font12">
              Average rent rate:{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageRentRate)?.toFixed(2)} Rs/sqft`
                : 'Awaited'}
            </Text>
            <Text className="font12">
              Average CAM chargeable :{' '}
              {propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable
                ? `${Number(propertyDetails?.moreBuildingInfo?.[0]?.averageCamChargeable)?.toFixed(2)} Rs/sqft/month`
                : 'Awaited'}
            </Text>
          </Space>
          <Text className="font12 p-10">Total Chargeable Area : {propertyDetails?.totalChargeableArea}</Text>
          <Text className="font12 p-10">
            Average Floor Plate :{' '}
            {propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet
              ? `${propertyDetails?.moreBuildingInfo?.[0]?.averageFloorPlateCarpet} sqft`
              : 'Awaited'}
          </Text>
          <Text className="font12 p-10">Efficiency : {propertyDetails?.efficiency}</Text>
          <Text className="font12 p-10">Grade of building: {propertyDetails?.gradeOfBuilding}</Text>
          <Text className="font12 p-10">Building Classification: {propertyDetails?.buildingClassification}</Text>
          <Text className="font12 p-10">Building Structure: {propertyDetails?.buildingStructure}</Text>
          <Text className="font18 font600">Amenities</Text>
          {propertyDetails?.amenities && (
            <div className="amenitiesDtlsSection__div">
              {propertyDetails?.amenities?.map((item) => {
                return (
                  <Col key={item} span={6} className="amenitiesDtlsSection__div__col">
                    {isEmpty(AmenitiesIconEnum?.[item?.toLowerCase()]) ? (
                      <img
                        title={'DEFAULT'}
                        src={AmenitiesIconEnum?.['DEFAULT']}
                        className="amenitiesDtlsSection__div__col__img"
                      />
                    ) : (
                      <img
                        title={item}
                        src={AmenitiesIconEnum?.[item?.toLowerCase()]}
                        className="amenitiesDtlsSection__div__col__img"
                      />
                    )}
                  </Col>
                );
              })}
            </div>
          )}
          {propertyDetails?.areaFacilities && propertyDetails?.areaFacilities?.length > 0 && (
            <div className="propertyListContainer__div__card__row1__col1__div1">
              {propertyDetails?.areaFacilities?.map((item) => {
                if (item?.facility && item?.metadata?.location)
                  return (
                    <span key={item.facility} className="propertyListContainer__div__card__row1__col1__div1__span">
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">{item?.facility}</p>
                      :&nbsp;{' '}
                      <p className="propertyListContainer__div__card__row1__col1__div1__span__para">
                        {item?.metadata?.location}
                      </p>
                    </span>
                  );
                return '';
              })}
            </div>
          )}
          <PhotoGallery photos={propertyDetails?.propertyImages} />
          <Reviews averageRating={propertyDetails?.averageRating} reviewsList={reviwesDetails} />
        </Space>
      </Card>
      <Card style={{ flex: '2' }}>
        <div className="tab-headings">
          <Text
            className="listHeading"
            style={{ color: activeTab === 'occupants' ? '#313131' : '#b7b7b7' }}
            onClick={() => handleTabChange('occupants')}
          >
            List of Occupants
          </Text>
          <Text
            className="listHeading"
            style={{ color: activeTab === 'vacantUnits' ? '#313131' : '#b7b7b7' }}
            onClick={() => handleTabChange('vacantUnits')}
          >
            List of Vacant Unit
          </Text>
        </div>
        <div className="data-grid-container">
          {activeTab === 'occupants' ? (
            <DataGrid
              columns={occupantsListColumns}
              data={occupantsDetails}
              onRowClick={(id) => onRowClick(id, 'occupants')}
            />
          ) : (
            <DataGrid
              columns={vacantUnitListColumns}
              data={vacantUnitDetails}
              onRowClick={(id) => onRowClick(id, 'vacantUnits')}
            />
          )}
        </div>
      </Card>
      <Modal
        centered
        open={transactionModal}
        onCancel={() => dispatch(setTransactionModal(false))}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={
          <Button key="ok" type="primary" onClick={() => navigate('/user/store')}>
            Make Payment
          </Button>
        }
        className="savedModal"
      >
        <Space direction="vertical" align="center">
          <Card
            style={{
              width: 250,
              marginTop: '20px',
              background: 'transparent linear-gradient(123deg, #0081FC 0%, #66B3FD 100%) 0% 0% no-repeat padding-box',
              boxShadow: '0px 0px 20px #00000029',
              textAlign: 'center',
            }}
          >
            <Space direction="vertical">
              <Title style={{ color: '#FFFFFF' }} level={4}>
                Oops insufficient points!
              </Title>
              <Text style={{ color: '#FFFFFF' }}>You need additional {insufficientPoints} points to unlock</Text>
            </Space>
          </Card>
        </Space>
      </Modal>
    </section>
  );
};

export default CommercialPropertyDetailsCard;
