import React from 'react';

const NewsPostIcon = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width="20.219" height="27" viewBox="0 0 20.219 27">
      <g id="_217522160" dataName=" 217522160">
        <path
          id="Path_17644"
          dataName="Path 17644"
          d="M56.039,169.727a.578.578,0,1,1,0-1.147h13.6a.578.578,0,1,1,0,1.147Z"
          transform="translate(-52.728 -144.859)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17645"
          dataName="Path 17645"
          d="M57.707,171.142a.283.283,0,1,1,0-.562H71.385a.283.283,0,1,1,0,.562Z"
          transform="translate(-54.436 -146.567)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17646"
          dataName="Path 17646"
          d="M61.047,148.853a.272.272,0,0,1-.218-.108l-1.236-1.607v1.433a.276.276,0,1,1-.553,0v-2.249a.281.281,0,0,1,.187-.266.266.266,0,0,1,.089-.015.272.272,0,0,1,.218.108l1.236,1.607v-1.433a.276.276,0,1,1,.553,0v2.249a.281.281,0,0,1-.187.266A.265.265,0,0,1,61.047,148.853Z"
          transform="translate(-55.477 -125.616)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17647"
          dataName="Path 17647"
          d="M80.466,148.861a.279.279,0,0,1-.276-.281v-2.249a.279.279,0,0,1,.276-.281H82.2a.281.281,0,0,1,0,.562H80.743V148.3H82.2a.281.281,0,0,1,0,.562Z"
          transform="translate(-73.179 -125.624)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17648"
          dataName="Path 17648"
          d="M80.466,154.3a.281.281,0,0,1,0-.562H82.2a.281.281,0,0,1,0,.562Z"
          transform="translate(-73.179 -132.261)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17649"
          dataName="Path 17649"
          d="M102.043,148.853a.274.274,0,0,1-.262-.193l-.469-1.444-.469,1.444a.274.274,0,0,1-.524,0l-.731-2.249a.282.282,0,0,1,.177-.355.289.289,0,0,1,.086-.015.274.274,0,0,1,.262.193l.469,1.444.47-1.444a.275.275,0,0,1,.525,0l.469,1.444.47-1.444a.277.277,0,0,1,.262-.193.249.249,0,0,1,.086.015.277.277,0,0,1,.161.14.282.282,0,0,1,.016.215l-.731,2.249a.277.277,0,0,1-.262.193Z"
          transform="translate(-89.317 -125.616)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17650"
          dataName="Path 17650"
          d="M126.016,148.853a.687.687,0,0,1-.613-.4.285.285,0,0,1-.012-.216.275.275,0,0,1,.509-.028l.039.083h1.088l.032-.129v-.3l-.006-.025-.028-.111h-1.014a.7.7,0,0,1-.683-.717v-.254a.7.7,0,0,1,.683-.717h.916a.687.687,0,0,1,.613.4.282.282,0,0,1-.13.376.281.281,0,0,1-.118.028.277.277,0,0,1-.249-.158L127,146.6h-1.088l-.032.129v.3l.006.026.028.111h1.014a.7.7,0,0,1,.683.717v.254a.7.7,0,0,1-.683.717h-.916Z"
          transform="translate(-110.959 -125.616)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17651"
          dataName="Path 17651"
          d="M38.745,66a1.6,1.6,0,0,1-1.1-.427,1.4,1.4,0,0,1-.456-1.024V45.593a1.393,1.393,0,0,1,.456-1.026,1.6,1.6,0,0,1,1.1-.427H55.854a1.605,1.605,0,0,1,1.1.426,1.4,1.4,0,0,1,.456,1.026V64.548A1.512,1.512,0,0,1,55.9,66H38.745Zm0-20.692a.3.3,0,0,0-.212.083.275.275,0,0,0-.09.2V64.549a.272.272,0,0,0,.09.2.31.31,0,0,0,.212.083H55.854a.3.3,0,0,0,.3-.284V45.593a.272.272,0,0,0-.09-.2.3.3,0,0,0-.212-.083Z"
          transform="translate(-37.19 -39)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17652"
          dataName="Path 17652"
          d="M91.47,13.126a.572.572,0,0,1-.569-.573v-2.41l-1.021.006.007,2.4a.569.569,0,1,1-1.138,0v-2.41A1.138,1.138,0,0,1,89.881,9h1.027a1.121,1.121,0,0,1,.8.335,1.136,1.136,0,0,1,.331.806v2.41a.572.572,0,0,1-.569.573Z"
          transform="translate(-80.285 -9)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17653"
          dataName="Path 17653"
          d="M93.186,14.55a.279.279,0,0,1-.276-.281v-2.41a.283.283,0,0,0-.083-.2.279.279,0,0,0-.2-.085H91.6a.286.286,0,0,0-.285.288v2.41a.276.276,0,1,1-.553,0V11.86a.845.845,0,0,1,.838-.85h1.027a.824.824,0,0,1,.592.249.847.847,0,0,1,.246.6v2.41a.279.279,0,0,1-.276.281Z"
          transform="translate(-82.001 -10.716)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17654"
          dataName="Path 17654"
          d="M83.895,32.678a.572.572,0,0,1-.569-.573V30.512l-4.154,0,.006,1.588a.569.569,0,1,1-1.138,0V30.512a1.138,1.138,0,0,1,1.131-1.142h4.161a1.138,1.138,0,0,1,1.131,1.142v1.593a.572.572,0,0,1-.569.573Z"
          transform="translate(-71.142 -26.391)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17655"
          dataName="Path 17655"
          d="M54.824,72.114a1.221,1.221,0,0,1-1.284-1.142V61.8a1.221,1.221,0,0,1,1.284-1.142H67.693A1.221,1.221,0,0,1,68.977,61.8V70.97a1.221,1.221,0,0,1-1.284,1.142H54.824Zm.007-1.142,12.862,0L67.687,61.8l-12.854.006v9.163Z"
          transform="translate(-51.149 -52.915)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17656"
          dataName="Path 17656"
          d="M81.484,111.608a.754.754,0,0,1-.764-.467l-.72-2.8c-.046-.18-.332-.276-.576-.276H73.27c-.246,0-.53.1-.576.276l-.72,2.8a.754.754,0,0,1-.764.467.988.988,0,0,1-.148-.01c-.42-.059-.7-.36-.616-.67l.72-2.8a2.071,2.071,0,0,1,2.107-1.211h6.154a2.071,2.071,0,0,1,2.107,1.211l.718,2.8a.452.452,0,0,1-.122.429.829.829,0,0,1-.5.241A1.2,1.2,0,0,1,81.484,111.608Z"
          transform="translate(-66.237 -92.35)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17657"
          dataName="Path 17657"
          d="M86.485,79.6A2.339,2.339,0,1,1,88.8,77.259,2.329,2.329,0,0,1,86.485,79.6Zm0-3.531a1.192,1.192,0,1,0,1.176,1.192A1.184,1.184,0,0,0,86.485,76.067Z"
          transform="translate(-76.375 -65.111)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17658"
          dataName="Path 17658"
          d="M82.339,125.524a.607.607,0,0,1-.116-.012.574.574,0,0,1-.442-.677l.237-1.157a.572.572,0,0,1,.557-.458.62.62,0,0,1,.116.012.564.564,0,0,1,.36.247.573.573,0,0,1,.082.43l-.237,1.157A.572.572,0,0,1,82.339,125.524Z"
          transform="translate(-74.541 -106.253)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
        <path
          id="Path_17659"
          dataName="Path 17659"
          d="M109.636,125.524a.572.572,0,0,1-.557-.458l-.237-1.157a.574.574,0,0,1,.442-.677.632.632,0,0,1,.116-.012.572.572,0,0,1,.557.459l.237,1.157a.577.577,0,0,1-.082.43.563.563,0,0,1-.361.247.594.594,0,0,1-.116.012Z"
          transform="translate(-97.213 -106.253)"
          fill="#b4b4b4"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};

export default NewsPostIcon;
