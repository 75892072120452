import React from 'react';
import { Pie } from 'react-chartjs-2';
import './pieChart.scss';

const PieChart = ({ item }) => {
  const chartData = {
    labels: [item.vacant,item.occupied],
    datasets: [
      {
        data: [item.vacant,item.occupied],
        backgroundColor: [
          '#D3D3D3', 
          'rgb(0, 128, 252)', 
        ],
      },
    ],
  };

 
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="chart-container"> 
    <Pie data={chartData} options={options} />
    <div className="pie-container">
      <h2>{item.total}</h2>
      <h3>{item.totalText}</h3>
    </div>
    <div className="legend-container">
      <div className="legend-item"></div>
      <h4>{item.occupiedText}</h4>
      <div className="legend-item legend-item--blue"></div>
      <h4>{item.vacantText}</h4>
    </div>
  </div>
  );
};

export default PieChart;