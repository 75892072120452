import React from 'react';

const ReportPostIcon = () => {
  return (
    <svg id="Report" xmlns="http://www.w3.org/2000/svg" width="20.203" height="27" viewBox="0 0 20.203 27">
      <path
        id="Path_17668"
        data-name="Path 17668"
        d="M32.122,35.507l-.019,0a.635.635,0,0,1-.572-.621V13.134a.631.631,0,0,1,.177-.425l4.045-4.023a.593.593,0,0,1,.425-.176H51.131a.6.6,0,0,1,.6.6V34.918l0,.019a.635.635,0,0,1-.621.572H32.122Zm18.387-1.225V9.733H36.8v3.433l0,.019a.635.635,0,0,1-.621.572H32.755V34.283H50.507ZM35.575,12.532V10.583l-1.949,1.949Z"
        transform="translate(-31.53 -8.51)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17669"
        data-name="Path 17669"
        d="M77.056,36.972a.676.676,0,0,1,0-1.352h6.2a.676.676,0,1,1,0,1.352Z"
        transform="translate(-70.053 -31.287)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17670"
        data-name="Path 17670"
        d="M79.141,113.458l-.021,0a.687.687,0,0,1-.62-.673v-8.306a.669.669,0,0,1,.676-.676h1.792a.669.669,0,0,1,.676.676v8.341l0,.021a.687.687,0,0,1-.673.62Zm1.517-.991-.054-7.676H79.484v7.676Z"
        transform="translate(-71.656 -89.375)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17671"
        data-name="Path 17671"
        d="M102.471,130.262l-.021,0a.687.687,0,0,1-.62-.673v-5.109a.669.669,0,0,1,.676-.676H104.3a.662.662,0,0,1,.491.214.62.62,0,0,1,.16.467v5.14l0,.021a.687.687,0,0,1-.673.62h-1.8Zm1.464-.991v-4.48h-1.04v4.48Z"
        transform="translate(-91.591 -106.179)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17672"
        data-name="Path 17672"
        d="M55.651,130.262l-.021,0a.687.687,0,0,1-.62-.673v-5.109a.669.669,0,0,1,.676-.676h1.792a.662.662,0,0,1,.491.214.62.62,0,0,1,.16.467v5.14l0,.021a.687.687,0,0,1-.673.62h-1.8Zm1.6-.831v-4.8h-1.3v4.8Z"
        transform="translate(-51.582 -106.179)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17673"
        data-name="Path 17673"
        d="M125.951,113.458l-.021,0a.687.687,0,0,1-.62-.673v-8.306a.669.669,0,0,1,.676-.676h1.792a.662.662,0,0,1,.491.214.62.62,0,0,1,.16.467v8.336l0,.021a.687.687,0,0,1-.673.62h-1.8Zm1.547-.991v-7.676h-1.207v7.676Z"
        transform="translate(-111.655 -89.375)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17674"
        data-name="Path 17674"
        d="M55.607,90.3a.681.681,0,0,1,0-1.352h11.5a.681.681,0,0,1,0,1.352Z"
        transform="translate(-51.257 -77.13)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17675"
        data-name="Path 17675"
        d="M55.607,72.572a.681.681,0,0,1,0-1.352h11.5a.681.681,0,0,1,0,1.352Z"
        transform="translate(-51.257 -62.006)"
        fill="#b4b4b4"
      />
      <path
        id="Path_17676"
        data-name="Path 17676"
        d="M55.726,54.842l-.018,0a.66.66,0,0,1-.547-.673.625.625,0,0,1,.576-.676h11.5a.681.681,0,0,1,0,1.352Z"
        transform="translate(-51.383 -46.881)"
        fill="#b4b4b4"
      />
    </svg>
  );
};

export default ReportPostIcon;
